import React from 'react';
import Modal from 'react-bootstrap/Modal';
import "./TicketModal.scss";
const TicketModal = ({ children, className, show, onHide }: any) => {
    return (
        <>
            <Modal className={`TicketModal ${className}`} show={show} onHide={onHide} centered backdrop="static">
                <Modal.Header closeButton>
                 </Modal.Header>
                <Modal.Body>
                    <div className="TicketModalInner">
                        {children}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default TicketModal; 