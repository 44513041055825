import React from 'react';
import '../LandingPage/Components/PlatformSec/PlatformSec.scss';
import { Container } from "react-bootstrap";
import Header from "../../common/Header/Header";
import ButtonCustom from "../../common/Button/ButtonCustom";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();

    return (
        <>
        <Header />
            <section id="home_sec" className="platform_sec error">
                 <div className="platform_sec_inner">
                    <Container className="text-center">
                         <h1><span>Oops!</span>Sorry, The Page Not Found</h1>
                         {/* <h4 className="text-white">Go back to home page</h4> */}
                         <ButtonCustom onClick={()=> navigate('/')} title="Go back to home page" className="headerBtn" />
                    </Container>
                </div>
            </section>
        </>
    )
}

export default ErrorPage