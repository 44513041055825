import * as async from 'async';
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import badge from "../../../assets/SvgIcons/Badge.svg";
import Toast from "../../../components/common/Toast";
import { NETWORK_DETAILS, PHASE } from "../../../config/constants";
import { RES_MESSAGES } from "../../../constants/messages";
import { isOwner } from "../../../handlers/common.handler";
import { useAppDispatch } from "../../../hooks/hooks";
import { IPhasesPricePayload } from "../../../interfaces/common";
import { IReduxUserDetails } from "../../../interfaces/store";
import { getPhasesPriceAction, getPhaseThreeTotalTicketsToSoldAction, setPhaseThreeMaxTicketAction, setPriceAction } from "../../../redux/actions/admin.action";
import { setLoader } from "../../../redux/actions/loader.action";
import { CommonService } from "../../../services/CommonService";
import PhaseCard from "../../components/Card/PhaseCard/PhaseCard";
import "./PriceManagement.scss";



const PriceManagement = () => {
  const dispatch = useAppDispatch();
  const userDetails: IReduxUserDetails = useSelector((state: any) => (state.user.walletAddress) ? state.user : false);
  const [errors, setErrors] = useState<any>();
  const [phaseOnePrice, setPhaseOnePrice] = useState<number>();
  const [phaseTwoPrice, setPhaseTwoPrice] = useState<number>();
  const [phaseThreePrice, setPhaseThreePrice] = useState<number>();
  const [maxPhaseThreeTickets, setMaxPhaseThreeTickets] = useState<number | void>();
  const [phasesPrice, setPhasesPrice] = useState<IPhasesPricePayload>();


  useEffect(() => {
  //  if (userDetails) {
    onInit();
    //resyncOnInit();
    //}
    return () => { }
  }, [])



  const onInit = async () => {
    //Using Callbacks
    try {
      dispatch(setLoader(true));
      await async.parallel({
        phasePrice: function (callback) {
          dispatch(getPhasesPriceAction(userDetails?.wallet)).then((response: IPhasesPricePayload | void) => {
            setPhasesPrice(response!);
            setPhaseOnePrice(response?.phaseOnePrice!);
            setPhaseTwoPrice(response?.phaseTwoPrice!);
            setPhaseThreePrice(response?.phaseThreePrice!);
            callback(null, response);
          }).catch((err: any) => {
            console.log('err', err);
          })
        },
        phaseThreeMaxTickets: function (callback) {
          dispatch(getPhaseThreeTotalTicketsToSoldAction(userDetails?.wallet)).then((response: number | void) => {
            setMaxPhaseThreeTickets(response);
            callback(null, response);
          }).catch((err: any) => {
            console.log('err', err);
          })
        },

      });
      dispatch(setLoader(false));
      //console.log(result);
    }
    catch (err) {
      dispatch(setLoader(false));
      console.log(err);
    }
  }


  // const onInit = async () => {
  //   dispatch(setLoader(true));
  //   const response = (await dispatch(getPhasesPriceAction(userDetails?.wallet)));
  //   const phaseThreeMaxTickets = (await dispatch(getPhaseThreeTotalTicketsToSoldAction(userDetails?.wallet)));
  //   if (response) {
  //     setPhasesPrice(response);
  //     setPhaseOnePrice(response.phaseOnePrice);
  //     setPhaseTwoPrice(response.phaseTwoPrice);
  //     setPhaseThreePrice(response.phaseThreePrice);

  //   }
  //   if (phaseThreeMaxTickets) {
  //     setMaxPhaseThreeTickets(phaseThreeMaxTickets);
  //   }
  //   dispatch(setLoader(false));

  // }

  //validation
  const checkValidation = () => {
    let isValidate = true;
    if (!phaseOnePrice) {
      setErrors("Price is required");
      isValidate = false;
    }
    if (!phaseTwoPrice) {
      setErrors("Price is required");
      isValidate = false;
    } if (!phaseThreePrice) {
      setErrors("Price is required");
      isValidate = false;
    }
    setErrors("");
    return isValidate;
  };

  //Phase price handler
  const onClickPriceHandler = async (event: MouseEvent, phase: number) => {
    event.preventDefault();
    let price;
    if (phase === PHASE.ONE) {
      price = phaseOnePrice;
    }
    if (phase === PHASE.TWO) {
      price = phaseTwoPrice;

    } if (phase === PHASE.THREE) {
      price = phaseThreePrice;
    }

    const isValidOwner = await dispatch(isOwner(userDetails?.walletAddress, userDetails.wallet));
    if (typeof isValidOwner === 'undefined') {
      return;
    }
    const validate = checkValidation();
    if (validate) {
      let result = await dispatch(
        setPriceAction(userDetails.wallet, {
          phaseNumber: phase,
          price: CommonService.convertWithDecimal(price, 10 ** NETWORK_DETAILS.USD_DECIMALS),
          walletAddress: userDetails.walletAddress,
        })
      );
      if (result && result.status === true) {
        Toast.success(RES_MESSAGES.PHASE_PRICE.ONE_SUCCESS);
      }
    }
    onInit();

  }

  //Phase price handler on change
  const phaseOnChangeHandler = (e: any, phase: number) => {
    const { value } = e.target;
    if (phase === PHASE.ONE) {
      setPhaseOnePrice(value);
    } else if (phase === PHASE.TWO) {
      setPhaseTwoPrice(value);
    } else {
      //phase 3
      setPhaseThreePrice(value)
    }

  }


  const maxTicketsHandler = async (event: MouseEvent) => {
    event.preventDefault();
    const isValidOwner = await dispatch(isOwner(userDetails?.walletAddress, userDetails.wallet));
    if (typeof isValidOwner === 'undefined') {
      return;
    }
    let result = await dispatch(
      setPhaseThreeMaxTicketAction(userDetails.wallet, {
        tickets: maxPhaseThreeTickets as number,
        walletAddress: userDetails.walletAddress,
      })
    );
    if (result && result.status === true) {
      Toast.success(RES_MESSAGES.MAX_TICKETS_SUCCESS);
    }
    onInit();
  }

  const maxTicketsOnChange = (event: { target: { value: number; }; }) => {
    const { value } = event.target;
    setMaxPhaseThreeTickets(value);
  }

  return (
    <Container fluid>
      <div className="price-management commn-bg">
        <PhaseCard icon={badge} title="Phase 1 (In $)" phaseType={PHASE.ONE} price={phaseOnePrice} handleChange={phaseOnChangeHandler} onClickHandler={onClickPriceHandler} error={errors} />
        <PhaseCard icon={badge} title="Phase 2 (In $)" phaseType={PHASE.TWO} price={phaseTwoPrice} handleChange={phaseOnChangeHandler} onClickHandler={onClickPriceHandler} error={errors} />
        <PhaseCard
          ticketShow
          icon={badge}
          title="Phase 3 (In $)"
          tlabel="Set Tickets for Phase 3"
          price={phaseThreePrice} handleChange={phaseOnChangeHandler} onClickHandler={onClickPriceHandler}
          phaseType={PHASE.THREE}
          maxTickets={maxPhaseThreeTickets}
          ticketsOnClick={maxTicketsHandler}
          ticketsOnChange={maxTicketsOnChange}
          error={errors}
        />
      </div>
    </Container>
  );
};

export default PriceManagement;
