import Toast from "../../components/common/Toast";
import {
  activePhaseInfoResponse,
  IHomePagePhaseInfoResponse
} from "../../interfaces/user";
import { CommonService } from "../../services/CommonService";
import {
  IBuyTicketRequest,
  IContractSendTxnConfirmResponse
} from "./../../interfaces/user";
import {
  buyTicketMethod,
  getActivePhaseInfoMethod,
  getETHBalanceMethod,
  getPerTicketPriceInBNBMethod,
  getTotalPriceToPayMethod
} from "./../../services/methods/userMethod";
import { AppDispatch } from "./../store";
import { setLoader } from "./loader.action";

/**
 * Action to get current contact owner address
 * @param walletAddress
 * @returns
 */
export const actionToGetETHBalance =
  (walletAddress: string) => async (): Promise<string | void> => {
    try {
      return (await getETHBalanceMethod(walletAddress)) as string;
    } catch (error: any) {
      return Toast.error(CommonService.getError(error));
    }
  };

/**
 * Action to get the ticket price in BNB
 * @param walletType
 * @returns
 */
export const ticketPriceAction =
  (walletType: string) => async (): Promise<number | void> => {
    try {
      return (await getPerTicketPriceInBNBMethod(walletType)) as number;
    } catch (error: any) {
      return Toast.error(CommonService.getError(error));
    }
  };

/**
 * Action to get active phase
 * @param walletType
 * @returns
 */
export const activePhaseInfoAction =
  (walletType: string) =>
  async (dispatch: AppDispatch): Promise<activePhaseInfoResponse | void> => {
    try {
      // let result
      dispatch(setLoader(true));
      const result = (await getActivePhaseInfoMethod(
        walletType
      )) as activePhaseInfoResponse;
      dispatch(setLoader(false));
      return result;
    } catch (error: any) {
      dispatch(setLoader(false));
      //throw CommonService.getError(error);
      return Toast.error(CommonService.getError(error));
    }
  };

//Action to buy ticket
export const buyTicketAction =
  (walletType: string, data: IBuyTicketRequest) =>
  async (
    dispatch: AppDispatch
  ): Promise<IContractSendTxnConfirmResponse | void> => {
    try {
      // let result
      // dispatch(setLoader(true));
      const result = (await buyTicketMethod(
        walletType,
        data
      )) as IContractSendTxnConfirmResponse;
      // dispatch(setLoader(false));
      return result;
    } catch (error: any) {
      dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
  };

/**
 * Action to get the ticket price in BNB
 * @param walletType
 * @returns
 */
export const ticketPriceToPayAction =
  (walletType: string, tickets: number) => async (): Promise<number | void> => {
    try {
      return (await getTotalPriceToPayMethod(walletType, tickets)) as number;
      //return undefined;
    } catch (error: any) {
      //throw CommonService.getError(error);
      return Toast.error(CommonService.getError(error));
    }
  };

export const homeSectionPhasesInfo =
  (walletType: string, tickets: number) =>
  async (): Promise<IHomePagePhaseInfoResponse | void> => {
    try {
      const ticketPriceInBNB = (await getTotalPriceToPayMethod(
        walletType,
        tickets
      )) as number;
      const activePhaseInfo = (await getActivePhaseInfoMethod(
        walletType
      )) as activePhaseInfoResponse;
      return { ticketPriceInBNB, activePhaseInfo };
    } catch (error) {
      //throw error;
      return Toast.error(CommonService.getError(error));
    }
  };