export const NETWORK_DETAILS = {
  NAME: process.env.REACT_APP_CHAIN_NAME as string,
  RPC: process.env.REACT_APP_RPC_URL as string,
  CHAIN_ID: process.env.REACT_APP_NETWORK_CHAIN_ID as string,
  SYMBOL: process.env.REACT_APP_CURRENCY_SYMBOL as string,
  EXPLORER: process.env.REACT_APP_BLOCK_EXPLORE_URL as string,
  DECIMAL: process.env.REACT_APP_CURRENCY_DECIMAL as unknown as number,
  HEX_ID: process.env.REACT_APP_HEX_ID as string,
  INFURA_ID: process.env.REACT_APP_INFURA_ID as string,
  CURRENCY_NAME: process.env.REACT_APP_CURRENCY_NAME as string,
  CURRENCY_SYMBOL: process.env.REACT_APP_CURRENCY_SYMBOL as string,
  BNB_DECIMALS: process.env.REACT_APP_CURRENCY_DECIMAL as unknown as number,
  USD_DECIMALS: process.env.REACT_APP_USD_DECIMAL as unknown as number,
  BSC_TXN_URL: process.env.REACT_APP_BSC_TXN_URL as unknown as string,
};

export const WALLETS = {
  METAMASK: "METAMASK",
  WALLET_CONNECT: "WALLET_CONNECT",
  BINANCE_WALLET: "BINANCE_WALLET",
};

export const CONTRACT = {
  // METAEAST_ICO_ADDRESS: process.env.REACT_APP_METAEAST_ICO_CONTRACT_ADDRESS,
  // USDT_ADDRESS: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
  // METAEAST_TOKEN_ADDRESS: process.env
  //   .REACT_APP_METAEAST_TOKEN_CONTRACT_ADDRESS as string,
  // METAEAST_VESTING_ADDRESS:
  //   process.env.REACT_APP_METAEAST_VESTING_CONTRACT_ADDRESS,
  KOOPVERSE_CONTACT_ADDRESS: process.env.REACT_APP_KOOPVERSE_CONTRACT,
};

// export const ALLOWED_CURRENCY_TYPES = {
//   ETH: 1,
//   USDT: 2,
// };

// export const CURRENCY_TYPE = {
//   ETH: "ETH",
//   USDT: "USDT",
// };

export const TIMEZONE = {
  IN: 0,
  UK: 1,
};

export const TIMEZONE_FILTER = {
  IN: 0,
  UK: 1,
  ALL: 3,
};


export const PHASE = {
  ONE: 0,
  TWO: 1,
  THREE: 2,
};

export const ALLOWANCE_MAXLIMIT = 10e40;
export const API_HOST = process.env.REACT_APP_API_HOST;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY as string;
export const DAPP_DEEPLINK = process.env.REACT_APP_DEEPLINK;
export const API = {
  // TRANSACTIONS: "user/transaction",
  // ADMIN: {
  //   ALL_TRANSACTIONS: "admin/all-transactions",
  //   GRAPH: "admin/total-token-sold/",
  //   VESTING_USER: "admin/registered-user",
  // },
  LOGIN: `${API_HOST}admin/login`,
  TICKETS_HISTORY: `${API_HOST}admin/ticket/list`,
  ADD_UPDATE__DETAILS: `${API_HOST}admin/manage-concert`,
  CONCERT_DETAILS: `${API_HOST}admin/concert/list`,
  SEND_OTP: `${API_HOST}user/send-otp`,
  VERIFY_OTP: `${API_HOST}user/verify-otp`,
  SUBSCRIBE: `${API_HOST}user/subscribe`,
};

export const BNB_DECIMAL = 10 ** 18;


