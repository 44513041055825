import { Col, Container, Form, Row } from "react-bootstrap";
import '../../../components/Pages/LandingPage/Components/PlatformSec/PlatformSec.scss';
import '../TimeManagement/TimeManagement.scss';
import './LoginPage.scss';
// import Header from "../../common/Header/Header";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonCustom from "../../../components/common/Button/ButtonCustom";
import Toast from "../../../components/common/Toast";
import { API } from "../../../config/constants";
import { RES_MESSAGES } from "../../../constants/messages";
import { RESPONSES } from "../../../constants/response";
import { useAppDispatch } from "../../../hooks/hooks";
import { ILoginResponse } from "../../../interfaces/common";
import { ILogin } from "../../../interfaces/user";
import * as types from "../../../redux/actionTypes";
import { apiCallPost } from "../../../services/axios";

const LoginPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>();
    const [inputValues, setInputValue] = useState<ILogin>({
        email: "",
        password: ""
    });
    // const userDetails: IReduxUserDetails = useSelector((state: any) => (state.user.walletAddress) ? state.user : false);



    //login form validation
    const formValidtion = () => {
        let isValidate = true;
        let errors: any = {};
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!inputValues.email) {
            errors["emailErr"] = "Email is required";
            isValidate = false;
        }
        if (!inputValues.password) {
            errors["passwordErr"] = "Password is required";
            isValidate = false;
        }
        if (inputValues.email) {
            if (!pattern.test(inputValues.email)) {
                errors["emailErr"] = "Please enter valid email address.";
                isValidate = false;
            }
        }

        setErrors(errors);
        return isValidate;
    };

    function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const { name, value } = event.target;

        setInputValue({ ...inputValues, [name]: value });
    }

    const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const validate = formValidtion();
            if (validate) {
                const response = (await apiCallPost(API.LOGIN, { email: inputValues.email, password: inputValues.password } ,"")) as ILoginResponse;
                if (response.status === RESPONSES.SUCCESS) {
                    dispatch({ type: types.IS_ADMIN, payload: true });
                    dispatch({ type: types.TOKEN, payload: response.data.token });
                    navigate('/admin/dashboard');
                    return Toast.success(RES_MESSAGES.LOGIN_SUCCESS);
                } else {
                    return Toast.error(response.message);
                } 
            }
        } catch (err: any) {
            const errMsg = err.message || RES_MESSAGES.COMMON_ERROR;
            return Toast.error(errMsg);
        }


    };
    return (
        <>
            {/* <Header /> */}
            <section id="home_sec" className="platform_sec error login">
                <div className="platform_sec_inner">
                    <Container className="text-center">
                        <div className="TimeMngbox loginbox">
                            <h1><span>Welcome Back</span> Admin!</h1>
                            <div className="inner">
                                <Form onSubmit={(e) => onSubmitHandler(e)}>
                                    <Row>
                                        <Col md={12}>
                                            <label className="mt-0">
                                                Email
                                            </label>
                                            <div className="input__wrap mt-3">
                                                <Form.Control type="email" name="email" className="pe-5" placeholder="Enter email" onChange={(e) => handleChange(e)} />
                                            </div>
                                            <div className="col-12 text-start">
                                                {
                                                    errors && errors['emailErr'] && (<>
                                                        <p className="text-danger"> {errors['emailErr']} </p>
                                                    </>)
                                                }
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <label>
                                                Password
                                            </label>
                                            <div className="input__wrap mt-3">
                                                <Form.Control type="password" name="password" className="pe-5" placeholder="Enter password" onChange={(e) => handleChange(e)} />
                                            </div>
                                            <div className="col-12 text-start">
                                                {
                                                    errors && errors['passwordErr'] && (<>
                                                        <p className="text-danger"> {errors['passwordErr']} </p>
                                                    </>)
                                                }
                                            </div>
                                        </Col>

                                    </Row>
                                    <ButtonCustom type="submit" title="Submit" className="headerBtn mt-5" />
                                </Form>

                            </div>


                        </div>
                        {/* <h4 className="text-white">Go back to home page</h4> */}
                    </Container>
                </div>
            </section>
        </>
    )
}
export default LoginPage;