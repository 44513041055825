export const RES_MESSAGES = {
  CLAIMED_SUCCESS: "Tokens claimed successfully",
  CONNECT_ERROR: "Please connect to suitable wallet first",
  WALLET_CONNECTED: "Wallet connected successfully",
  TGE_CLAIMED: "TGE claimed successfully",
  CONNECT_MSG: "Please connect to wallet to book ticket",
  INVALID_OWNER: "Please connect with valid owner address",
  BUY_SUCCESS:
    "Tickets purchased sucessfully, please check your provided mail inbox",
  LOGIN_SUCCESS: "Logged In successfully",
  COMMON_ERROR: "Something went wrong, please try again later",

  PHASE_PRICE: {
    ONE_SUCCESS: "Phase one price updated successfully",
    TWO_SUCCESS: "Phase two price updated successfully",
    THREE_SUCCESS: "Phase three price updated successfully",
  },
  MAX_TICKETS_SUCCESS: "Phase three max tickets updated successfully",
  PHASES_TIME_SUCCESS: "Phases time updated successfully",
  CONCERT_SUCCESS: "Concert details saved successfully",
  MAX_TICKETS_REACHED: "You have reached the maximum limit of tickets purchase",
  OTP: {
    RESEND: "A new OTP has been re-sent on your email",
  },
  SUBSCRIBE_SUCCESS: "Subscribed successfully",
};
