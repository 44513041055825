import { KOOPVESE_ABI } from './../abis/koopverse.abi';
import { DYNAMIC_ABI } from "./../abis/dynamic.abi";
import { provider } from "./walletConnect/provider";
import { CONTRACT, WALLETS } from "./../config/constants";
import web3 from "web3";
import { NETWORK_DETAILS } from "../config/constants";


/**
 * Function to detect provider
 * @returns
 */
const detectProvider = () => {
  try {
    let provider;
    if (typeof window !== "undefined") {
      const { ethereum, web3 } = window as any;
      if (ethereum || web3) {
        provider = ethereum;
      } else {
        provider = NETWORK_DETAILS.RPC;
      }
    } else {
      provider = NETWORK_DETAILS.RPC;
    }

    return provider;
  } catch (error: any) {
    console.log("error under detect provider handler:", error);
  }
};

/**
 * Initialize instance
 */
const instanceInit = async (): Promise<void> => {
  try {
    let web3Instance: any;
    let currentProvider: any = await detectProvider();
    web3Instance = await new web3(currentProvider);
    if (currentProvider) {
      await new web3Instance.eth.Contract(
        KOOPVESE_ABI,
        CONTRACT.KOOPVERSE_CONTACT_ADDRESS
      );
    } else {
      console.log("please connect with a desire provider");
    }
  } catch (error: any) {
    console.log("Error under instance init handler", error);
  }
};

instanceInit();

/**
 * Koop Verse  contract instance with ABI
 * @param walletType
 * @returns
 */
const koopVerseContractInstance = async (walletType: string) => {
  try {
    const { ethereum, BinanceChain } = window as any;
    if (walletType === WALLETS.METAMASK) {
      const initInstance = await new web3(ethereum);
      return await new initInstance.eth.Contract(
        KOOPVESE_ABI,
        CONTRACT.KOOPVERSE_CONTACT_ADDRESS
      );
    } else if (walletType === WALLETS.BINANCE_WALLET) {
       const initInstance =  await new web3(BinanceChain);
      return await new initInstance.eth.Contract(
         KOOPVESE_ABI,
        CONTRACT.KOOPVERSE_CONTACT_ADDRESS
      );
    }else if (walletType === WALLETS.WALLET_CONNECT) {
      const initInstance = await new web3(provider);
      return await new initInstance.eth.Contract(
        KOOPVESE_ABI,
        CONTRACT.KOOPVERSE_CONTACT_ADDRESS
      );
    } else {
      //instance with RPC
      let initInstance: any = await new web3(NETWORK_DETAILS.RPC as string);
      return await new initInstance.eth.Contract(
        KOOPVESE_ABI,
        CONTRACT.KOOPVERSE_CONTACT_ADDRESS
      );
    }
  } catch (err: any) {
    console.log("Error under koopverse instance", err);
  }
};

/**
 * Koop verse contract instance with dynamic ABI
 * @param walletType 
 * @returns 
 */
const koopVerseDynamicInstance = async (walletType: string) => {
  try {
    const { ethereum } = window as any;
    if (walletType === WALLETS.METAMASK) {
      const initInstance = await new web3(ethereum);
      return await new initInstance.eth.Contract(
        DYNAMIC_ABI,
        CONTRACT.KOOPVERSE_CONTACT_ADDRESS
      );
    }else if (walletType === WALLETS.BINANCE_WALLET) {
       const initInstance =  await new web3(NETWORK_DETAILS.RPC);
      return await new initInstance.eth.Contract(
         KOOPVESE_ABI,
        CONTRACT.KOOPVERSE_CONTACT_ADDRESS
      );
    } else if (walletType === WALLETS.WALLET_CONNECT) {
      const initInstance = await new web3(provider);
      return await new initInstance.eth.Contract(
        DYNAMIC_ABI,
        CONTRACT.KOOPVERSE_CONTACT_ADDRESS
      );
    } else {
      //instance with RPC
      let initInstance: any = await new web3(NETWORK_DETAILS.RPC as string);
      return await new initInstance.eth.Contract(
        DYNAMIC_ABI,
        CONTRACT.KOOPVERSE_CONTACT_ADDRESS
      );
    }
  } catch (err: any) {
    console.log("Error under dynamic instance", err);
  }
};


export const ContractInstanceHandler = {
  instanceInit,
  detectProvider,
  koopVerseDynamicInstance,
  koopVerseContractInstance
};
