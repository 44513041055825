export const WALLET_ADDRESS = "WALLET_ADDRESS";
export const WALLET = "WALLET";
export const IS_ADMIN = "IS_ADMIN";
export const NETWORK = "NETWORK";
export const SET_LOADING = "SET_LOADING";
export const TOKEN = "TOKEN";
export const CONCERT = "CONCERT";



