import moment from "moment";
import * as momentTimezone from "moment-timezone";
import { AnyAction } from "redux";
import Toast from "../components/common/Toast";
import { PHASE } from "../config/constants";
import { getOwnerAddressAction } from "../redux/actions/admin.action";
import { RES_MESSAGES } from "./../constants/messages";

//increment ticket counter
export const incrementCounter = (
  numberOfTickets: number,
  setNumberOfTickets: any,
  activePhase: number,
  maxPhaseThreeTickets: number
) => {
  // console.log("activePhase", activePhase);
  if (Number(activePhase) === PHASE.THREE) {
    if (numberOfTickets <= maxPhaseThreeTickets) {
      setNumberOfTickets(numberOfTickets + 1);
    } else {
      return Toast.error(RES_MESSAGES.MAX_TICKETS_REACHED);
    }
  } else {
    setNumberOfTickets(numberOfTickets + 1);
  }
};
//decrement ticket counter
export const decrementCounter = (
  numberOfTickets: number,
  setNumberOfTickets: any
) => {
  if (numberOfTickets > 1) {
    setNumberOfTickets(numberOfTickets - 1);
  }
};
//To show alert if wallet not connected
export const connectToWalletAlert = () => {
  return Toast.error(RES_MESSAGES.CONNECT_MSG);
};
//To check if phase is active
export const checkIfPhaseActive = (startTime: number, endTime: number) => {
  // console.log("moment().unix()", moment().unix());
  if (
    (moment().unix() >= startTime && moment().unix() <= endTime) ||
    endTime == 0
  ) {
    return true;
  } else {
    return false;
  }
};

//To get timezone based date time
export const convertDatesByTimeZone = (startDateTime: number, timezone: number) => {
  if (timezone === 0) {
    return momentTimezone
      .unix(startDateTime)
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DD HH:mm A z");
  } else {
    return moment.unix(startDateTime).utc().format("YYYY-MM-DD HH:mm A z");
  }
};

//check if address is owner address
export const isOwner =
  (walletAddress: string, walletType: string) =>
  async (dispatch: any | AnyAction): Promise<boolean | void> => {
    try {
      if (typeof walletType === "undefined") {
        return Toast.error(RES_MESSAGES.CONNECT_ERROR);
      }
      const ownerAddress = (await dispatch(
        getOwnerAddressAction(walletType)
      )) as unknown as string;
      if (ownerAddress !== walletAddress) {
        return true;
      } else {
        return Toast.error(RES_MESSAGES.INVALID_OWNER);
      }
    } catch (error: any) {
      return Toast.error(error?.message);
    }
  };
  

