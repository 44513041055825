import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./AboutSec.scss";
import AboutImg from "../../../../../assets/Images/about-img.png";
import ButtonCustom from "../../../../common/Button/ButtonCustom";
import AboutFAQ from "../AboutFAQ/AboutFAQ";
import Footer from "../../../../common/Footer/Footer";
const AboutSec = () => {
  return (
    <>
      <section className="AboutSec">
        <Container>
          <div className="eventSec">
            <Row className="align-items-center">
              <Col lg={6} className="mb-4 mb-lg-0">
                <img src={AboutImg} alt="" />
              </Col>
              <Col lg={6}>
                <h2>About Event</h2>
                <h3 className="top">
                  This event will be organised as the non-profit event & all of
                  the proceeds and revenue will be handed over to the foundation
                  and the beneficiaries of Sidhu Moose Wala for the charity
                  campaign and programs at His birthplace.{" "}
                </h3>
                <p>
                  Koopverse, the metaverse ecosystem of Koop Labs LLC is going
                  to bring the first of its kind metaverse tribute on stage
                  event. Koopverse is developing a unique virtual Arena with VR
                  / AR tools, specifically for this event It will be the most
                  appealing tribute to our legendary musician, rapper, actor and
                  song writer, the Greatest Sidhu Moose Wala. A legend and
                  “cultural icon of Punjab” was a rise to fame.
                </p>
                <p className="mb-5">
                  Koopverse is developing an online Virtual Arena with VR / AR
                  facilities for the audience all over the world to maximize the
                  benefit of the next level of the online events industry. This
                  tribute event will be LIVE on 29 5 – 2023 at Koopverse Arena
                  (Online).
                </p>
                <h3 className="top">
                  Whitelist for Private Sale has been activated. If you are
                  interested to contribute to this futuristic and practical use
                  case of Metaverse and Blockchain ecosystem, kindly click below
                </h3>

                <ButtonCustom
                  title="Click here"
                  className="headerBtn"
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSfV0Q5cgguktOCupVAj0dJAICxQ0O1RSxQn_BrQP3nNbLYcOw/viewform",
                      "_blank"
                    )
                  }
                />
              </Col>
            </Row>
          </div>
          <AboutFAQ />
        </Container>
      </section>
    </>
  );
};

export default AboutSec;
