import Form from "react-bootstrap/Form";
import ButtonCustom from "../../../../components/common/Button/ButtonCustom";

const PhaseCard = ({ title, label, icon, ticketShow, tlabel, onClickHandler, handleChange, price, phaseType, maxTickets, ticketsOnClick, ticketsOnChange, error }: any) => {
  return (
    <div className="dashboard-card">
      <div className="dashboard-card__icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="dashboard-card__content">
      <Form.Label>{title}</Form.Label>
        {/* <h3 className="mb-2"></h3> */}
        {label && <Form.Label className="mb-2">{label}</Form.Label>}
        <div className="input__wrap">
          <Form.Control type="number" value={price} placeholder="Enter Amount" onChange={(e) => handleChange(e, phaseType)} pattern="^([A-Z]+, )?$" />
          <ButtonCustom title="Update" onClick={(e: MouseEvent) => onClickHandler(e, phaseType)} />
          {error && (<>
            <p className="text-danger"> {error} </p>
          </>)
          }
        </div>
        {ticketShow && (
          <>
            {ticketShow && (
              <Form.Label className="mt-5">{tlabel}</Form.Label>
            )}
            <div className="input__wrap">
              <Form.Control type="number" value={maxTickets} placeholder="Enter no. of tickets" onChange={(e) => ticketsOnChange(e)} pattern="^([A-Z]+, )?$" />
              <ButtonCustom title="Update" onClick={(e: MouseEvent) => ticketsOnClick(e)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PhaseCard;
