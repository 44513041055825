import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLayout from "./Admin/components/AdminLayout/AdminLayout";
import Dashboard from "./Admin/pages/Dashboard/Dashboard";
import LoginPage from "./Admin/pages/LoginPage/LoginPage";
import PriceManagement from "./Admin/pages/PriceManagement/PriceManagement";
import Settings from "./Admin/pages/Settings/Settings";
import TicketInfo from "./Admin/pages/TicketInfo/TicketInfo";
import TimeManagement from "./Admin/pages/TimeManagement/TimeManagement";
import ErrorPage from "./components/Pages/ErrorPage/ErrorPage";
import Home from "./components/Pages/LandingPage/Home";
import { WALLETS } from "./config/constants";
import { useAppDispatch } from "./hooks/hooks";
import {
  connectToWallet,
  connectWithBinanceWalletHandler,
  connectWithWalletConnect,
} from "./redux/actions/connect.action";
import AdminAuthGuard from "./routes/Guard/AdminAuthGuard";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const userDetails = useSelector((state: any) =>
    state.user.walletAddress ? state.user : false
  );
  useEffect(() => {
    onInit();
  }, []);
  const onInit = async () => {
    if (userDetails && userDetails?.walletAddress) {
      if (userDetails.wallet === WALLETS.METAMASK) {
        await dispatch(connectToWallet(userDetails.wallet));
      }
      if (userDetails.wallet === WALLETS.BINANCE_WALLET) {
        await dispatch(connectWithBinanceWalletHandler(userDetails.wallet));
      }
      if (userDetails.wallet === WALLETS.WALLET_CONNECT) {
        await dispatch(connectWithWalletConnect(userDetails.wallet));
      }
    }
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="admin/login" element={<LoginPage />} />
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<AdminAuthGuard component={Dashboard} />} />
            <Route
              path="dashboard"
              element={<AdminAuthGuard component={Dashboard} />}
            />
            <Route
              path="ticket-info"
              element={<AdminAuthGuard component={TicketInfo} />}
            />
            <Route
              path="price-management"
              element={<AdminAuthGuard component={PriceManagement} />}
            />
            <Route
              path="time-management"
              element={<AdminAuthGuard component={TimeManagement} />}
            />
            {/* <Route path="dashboard" element={<Dashboard />} />
            <Route path="ticket-info" element={<TicketInfo />} />
            <Route path="price-management" element={<PriceManagement />} />
            <Route path="time-management" element={<TimeManagement />} /> */}
            <Route
              path="setting"
              element={<AdminAuthGuard component={Settings} />}
            />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
export default App;
