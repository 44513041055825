import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { default as web3 } from "web3";
// import HandsUp from '../../../../../assets/Images/bg_handsup.png';
// import Moosatext from '../../../../../assets/Images/moosaText.png';
import NeverDie from "../../../../../assets/Images/never-die.png";
import QRcode from "../../../../../assets/Images/qrcode.png";
// import Sidhu from '../../../../../assets/Images/Sidhu-image.png';
// import SidhuMobile from '../../../../../assets/Images/SidhuMobile.png';
// import StayTuned from '../../../../../assets/Images/Stay-Tuned.png';
// import MoosatextMob from '../../../../../assets/Images/TextMobile.png';
import * as async from "async";
import { ArrowIcon } from "../../../../../assets/SvgIcons/SvgIcons";
import {
  API,
  NETWORK_DETAILS,
  TIMEZONE
} from "../../../../../config/constants";
import { RES_MESSAGES } from "../../../../../constants/messages";
import { RESPONSES } from "../../../../../constants/response";
import {
  checkIfPhaseActive,
  connectToWalletAlert,
  decrementCounter,
  incrementCounter
} from "../../../../../handlers/common.handler";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { IReduxUserDetails } from "../../../../../interfaces/store";
import {
  activePhaseInfoResponse,
  IConcertDetailsResponse,
  IContractSendTxnConfirmResponse,
  IHomePagePhaseInfoResponse
} from "../../../../../interfaces/user";
import { getPhaseThreeTotalTicketsToSoldAction } from "../../../../../redux/actions/admin.action";
import { setLoader } from "../../../../../redux/actions/loader.action";
import {
  buyTicketAction,
  homeSectionPhasesInfo,
  ticketPriceToPayAction
} from "../../../../../redux/actions/user.action";
import { apiCallGet, apiCallPost } from "../../../../../services/axios";
import { CommonService } from "../../../../../services/CommonService";
import ButtonCustom from "../../../../common/Button/ButtonCustom";
import TicketModal from "../../../../common/TicketModal/TicketModal";
import Toast from "../../../../common/Toast";
// @ts-ignore
import OTPInput, { ResendOTP } from "otp-input-react";
import { ISendOTPApiResponse } from "../../../../../interfaces/common";
import "./PlatformSec.scss";
const BigNumber = require("big-number");
const PlatformSec = () => {
  const [OTP, setOTP] = useState("");
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [showBuyModal, setshowBuyModal] = useState(false);
  const [showOTP, setshowOTP] = useState(false);
  const [numberOfTickets, setNumberOfTickets] = useState<number>(1);
  const [selectedTimeZone, setSelectedTimeZone] = useState<number>(0);
  const [buyerEmailId, setBuyerEmailId] = useState<string>("");
  const [errors, setErrors] = useState<any>();
  const [emailErr, setEmailErrors] = useState<any>();
  const [activePhaseData, setActivePhaseData] =
    useState<Partial<activePhaseInfoResponse>>();
  const [totalPhaseThreeTickets, setTotalPhaseThreeTickets] =
    useState<number>();
  const [concertDetails, setConcertDetails] =
    useState<IConcertDetailsResponse>();
  const [priceToPayInBNB, setPriceToPayInBNB] = useState<number>(0);
  const [showContinueButton, setShowContinueButton] = useState<boolean>(true);
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [verificationId, setVerificationId] = useState<string>("");


  const handleClose = () => {
    setShow(false);
    setErrors("");
    setPriceToPayInBNB(0);
    setNumberOfTickets(1);
    setSelectedTimeZone(0);
  };
  const handleShow = () => setShow(true);
  const handleBuyModal = () => {
    setshowBuyModal(false);
    setNumberOfTickets(1);
    setSelectedTimeZone(0);
    setBuyerEmailId("");
    setPriceToPayInBNB(0);
    setIsTermsChecked(false);
    setVerificationId("");
    setOTP("");
    setIsOtpSent(false);
    setIsTermsChecked(false);
  };
  const handleBuyModalShow = () => setshowBuyModal(true);
  const handleOTPModal = () => setshowOTP(true);
  const handleOTPClose = () => {
    setshowOTP(false);
    setNumberOfTickets(1);
    setSelectedTimeZone(0);
    setBuyerEmailId("");
    setPriceToPayInBNB(0);
    setVerificationId("");
    setOTP("");
    setIsOtpSent(false);
  };
  const handleCheckBoxChange = (e: any) => {
    const { checked } = e.target;
    setIsTermsChecked(checked);
    if (checked) {
      setErrors("");
    }
  }
  const userDetails: IReduxUserDetails = useSelector((state: any) =>
    state.user.walletAddress ? state.user : false
  );
  //Hook on page reload
  useEffect(() => {
    if (userDetails && userDetails?.walletAddress && show) {
      // phaseInfo();
      // maxTicketsInPhaseThree();
      // getConcetDetails();
      onInit();
    }
    return () => { };
  }, [show]);
  useEffect(() => {
    if (userDetails && userDetails?.walletAddress && show) {
      totalPriceToPayInBnb();
    }
    return () => { };
  }, [numberOfTickets]);
  const onInit = async () => {
    //Using Callbacks
    try {
      dispatch(setLoader(true));
      const results = await async.parallel({
        homeSectionPhasesInfo: function (callback) {
          dispatch(homeSectionPhasesInfo(userDetails?.wallet, numberOfTickets))
            .then((response: IHomePagePhaseInfoResponse | void) => {
              if (response) {
                const activePhase = response.activePhaseInfo;
                setPriceToPayInBNB(
                  response.ticketPriceInBNB / 10 ** NETWORK_DETAILS.BNB_DECIMALS
                );
                const isPhaseActive = checkIfPhaseActive(
                  activePhase?.startTime as number,
                  activePhase?.endTime as number
                );
                // response?.activePhaseInfo = isPhaseActive;
                setShowContinueButton(isPhaseActive);
                activePhase.isPhaseActive = isPhaseActive;
                setActivePhaseData(response.activePhaseInfo);
              }
              callback(null, response);
            })
            .catch((err: any) => {
              console.log("err", err);
            });
        },
        concertDetails: function (callback) {
          apiCallGet(API.CONCERT_DETAILS)
            .then((response: AxiosResponse | any) => {
              if (response.status === RESPONSES.SUCCESS) {
                setConcertDetails(response.data[0]);
              }
              callback(null, response);
            })
            .catch((err: any) => {
              console.log("err", err);
              const errMsg = RES_MESSAGES.COMMON_ERROR;
              return Toast.error(errMsg);
            });
        },
        maxTicketsInPhaseThree: function (callback) {
          dispatch(getPhaseThreeTotalTicketsToSoldAction(userDetails?.wallet))
            .then((response) => {
              if (response) {
                setTotalPhaseThreeTickets(response);
              }
              callback(null, response);
            })
            .catch((err: any) => {
              console.log("err", err);
            });
        },
      });
      dispatch(setLoader(false));
      //console.log(results);
      // results is equal to ['one','two'] even though
      // the second function had a shorter timeout.
    } catch (err) {
      dispatch(setLoader(false));
    }
  };
  //validation
  const checkValidation = () => {
    let isValidate = true;
    let errors: any = {};
    if (!numberOfTickets) {
      errors["ticketErr"] = "Select atleast one ticket";
      isValidate = false;
    }
    if (selectedTimeZone < 0 || typeof selectedTimeZone === "undefined") {
      errors["timezoneErr"] = "Select timezone";
      isValidate = false;
    }
    setErrors(errors);
    return isValidate;
  };
  //get max ticket in phase 3 from contract
  // const maxTicketsInPhaseThree = async () => {
  //   const maxPhaseThreeTickets = (await dispatch(getPhaseThreeTotalTicketsToSoldAction(userDetails?.wallet)));
  //   if (maxPhaseThreeTickets) {
  //     setTotalPhaseThreeTickets(maxPhaseThreeTickets);
  //   }
  // }
  //Total price to pay wrt tickets
  const totalPriceToPayInBnb = async () => {
    dispatch(setLoader(true));
    const getTicketsPriceInBnb = await dispatch(
      ticketPriceToPayAction(userDetails?.wallet, numberOfTickets)
    );
    if (getTicketsPriceInBnb) {
      setPriceToPayInBNB(
        getTicketsPriceInBnb / 10 ** NETWORK_DETAILS.BNB_DECIMALS
      );
    }
    dispatch(setLoader(false));
  };
  //get concert details
  // const getConcetDetails = async () => {
  //   try {
  //     const response = (await apiCallGet(API.CONCERT_DETAILS)) as AxiosResponse;
  //     if (response.status === RESPONSES.SUCCESS) {
  //       setConcertDetails(response.data[0]);
  //     }
  //   } catch (error) {
  //     const errMsg = RES_MESSAGES.COMMON_ERROR;
  //     return Toast.error(errMsg);
  //   }
  // }
  //Validations
  const buyValidation = () => {
    let isValidate = true;
    let errors: any = {};
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!buyerEmailId) {
      errors["emailErr"] = "Email is required";
      isValidate = false;
    }
    if (buyerEmailId) {
      if (!pattern.test(buyerEmailId)) {
        errors["emailErr"] = "Please enter valid email address.";
        isValidate = false;
      }
    }
    if (!isTermsChecked) {
      errors["termsErr"] = "Please accept terms & conditions";
      isValidate = false;
    }
    setErrors(errors);
    return isValidate;
  };


  const VerifyValidation = () => {
    let isValidate = true;
    let errors: any = {};
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!buyerEmailId) {
      errors["emailErr"] = "Email is required";
      isValidate = false;
    }
    if (buyerEmailId) {
      if (!pattern.test(buyerEmailId)) {
        errors["emailErr"] = "Please enter valid email address.";
        isValidate = false;
      }
    }
    // if (!isTermsChecked) {
    //   errors["termsErr"] = "Please accept terms & conditions";
    //   isValidate = false;
    // }
    setErrors(errors);
    return isValidate;
  };
  //function on page reload
  // const phaseInfo = async () => {
  //   const activePhase = await dispatch(activePhaseInfoAction(userDetails?.wallet));
  //   if (activePhase) {
  //     const isPhaseActive = checkIfPhaseActive(activePhase?.startTime as number, activePhase?.endTime as number);
  //     activePhase.isPhaseActive = isPhaseActive;
  //     setActivePhaseData(activePhase);
  //   }
  // }
  //buy ticket handler
  const continueHandler = (event: MouseEvent) => {
    event.preventDefault();
    const validate = checkValidation();
    if (validate) {
      //handleClose();
      setShow(false);
      handleOTPModal();
      // handleBuyModalShow();
    }
  };
  //buy handler
  const buyHandler = async (event: React.MouseEvent) => {
    event.preventDefault();
    const validate = buyValidation();
    if (validate) {
      dispatch(setLoader(true));
      //console.log('numberOfTickets', numberOfTickets)
      const getTicketsPrice = await dispatch(
        ticketPriceToPayAction(userDetails?.wallet, numberOfTickets)
      );
      //  console.log('getTicketsPrice', getTicketsPrice)
      //console.log('selectedTimeZone', selectedTimeZone)
      const response = (await dispatch(
        buyTicketAction(userDetails?.wallet, {
          ticketsToBuy: numberOfTickets,
          // email: ethers.utils.formatBytes32String(buyerEmailId),
          email: web3.utils.toHex(buyerEmailId),
          timeZoneType: selectedTimeZone,
          walletAddress: userDetails?.walletAddress,
          // amountToPay: (activePhaseData?.ticketPrice!) * (numberOfTickets),
          amountToPay: getTicketsPrice,
        })
      )) as IContractSendTxnConfirmResponse;
      if (response && response.status) {
        Toast.success(RES_MESSAGES.BUY_SUCCESS);
      }
      //phaseInfo();
      onInit();
      handleBuyModal();
      handleClose();
      dispatch(setLoader(false));
    }
  };
  const toFixDecimals = () => {
    const bnbFixedValue = CommonService.exponentialToDecimal(
      activePhaseData?.ticketPrice! / 10 ** NETWORK_DETAILS.BNB_DECIMALS
    );
    return Number(bnbFixedValue).toFixed(4);
  };

  //otp handler block
  const sendOtpHandler = async (event: MouseEvent) => {
    //alert("here");
    event.preventDefault();

    try {
      const validate = VerifyValidation();
      if (validate) {
        const response = (await apiCallPost(
          API.SEND_OTP,
          { email: buyerEmailId },
          {}
        )) as ISendOTPApiResponse;
        if (response.status === RESPONSES.SUCCESS) {
          setIsOtpSent(true);
          setVerificationId(response.data._id);
        } else {
          return Toast.error(response.message);
        }
      }
    } catch (err: any) {
      const errMsg = err.message || RES_MESSAGES.COMMON_ERROR;
      return Toast.error(errMsg);
    }
  };

  const reSendOtpHandler = async () => {
    // event.preventDefault();

    try {
      const response = (await apiCallPost(
        API.SEND_OTP,
        { email: buyerEmailId },
        {}
      )) as ISendOTPApiResponse;
      if (response.status === RESPONSES.SUCCESS) {
        setIsOtpSent(true);
        return Toast.success(RES_MESSAGES.OTP.RESEND);
      } else {
        return Toast.error(response.message);
      }

    } catch (err: any) {
      const errMsg = err.message || RES_MESSAGES.COMMON_ERROR;
      return Toast.error(errMsg);
    }
  };


  const verifyOtpHandler = async (event: MouseEvent) => {
    event.preventDefault();
    console.log('first', verificationId, OTP)
    try {
      const response = (await apiCallPost(
        API.VERIFY_OTP,
        { _id: verificationId, otp: Number(OTP) },
        {}
      )) as ISendOTPApiResponse;
      if (response.status === RESPONSES.SUCCESS) {
        // setIsOtpSent(true);
        setshowOTP(false);
        setVerificationId("");
        handleBuyModalShow();
        //return Toast.success(RES_MESSAGES.OTP.RESEND);
      } else {
        return Toast.error(response.message);
      }
    } catch (err: any) {
      const errMsg = err.message || RES_MESSAGES.COMMON_ERROR;
      return Toast.error(errMsg);
    }
  }
  return (
    <>
      <section id="home_sec" className="platform_sec">
        <img
          className="HandsUp"
          src="https://d1ygcv609zmhy5.cloudfront.net/bg_handsup.png"
          alt=""
        />
        <div className="platform_sec_inner">
          <Container>
            <div className="MobileHandle">
              <img
                className="SidhuHimSelf"
                src="https://d1ygcv609zmhy5.cloudfront.net/Sidhu-image.png"
                alt=""
              />
              <img
                className="StayTuned"
                src="https://d1ygcv609zmhy5.cloudfront.net/Stay-Tuned.png"
                alt=""
              />
              <img
                className="SidhuHimSelfMobile"
                src="https://d1ygcv609zmhy5.cloudfront.net/SidhuMobile.png"
                alt=""
              />
            </div>
            <div className="content_handle">
              <img
                className="MooseText desk"
                src="https://d1ygcv609zmhy5.cloudfront.net/moosaText.png"
                alt="text"
              />
              <img
                className="MooseText mob"
                src="https://d1ygcv609zmhy5.cloudfront.net/TextMobile.png"
                alt="text"
              />
              <h1>
                <span>
                  THE 1<sup>ST</sup> METAVERSE <br /> ON-STAGE TRIBUTE EVENT
                </span>
                TO THE BLOCKBUSTER
              </h1>
              {userDetails && userDetails.walletAddress ? (
                <>
                  <ButtonCustom
                    title="Book Ticket"
                    className="headerBtn"
                    onClick={handleShow}
                  />
                </>
              ) : (
                <>
                    <ButtonCustom
                      title="Book Ticket"
                      className="headerBtn"
                      onClick={() => connectToWalletAlert()}
                    />
                  </>
              )}
              <div className="bottom">
                <img className="NeverDie" src={NeverDie} alt="" />
                <h2 className="h1">
                  Date<span>: 29 5</span> 2023
                </h2>
              </div>
            </div>
          </Container>
        </div>
      </section>
      <TicketModal show={show} onHide={handleClose}>
        <div className="TicketHandle">
          {/* <h2>Virtual music concert in Metaverse</h2> */}
          <h2>{concertDetails?.concert_name}</h2>
          <ul>
            <li>
              <strong>Ticket Number</strong> <strong>KOOPXXXXX</strong>
            </li>
            <li>
              <strong>Price</strong>{" "}
              <strong>
                {activePhaseData?.isPhaseActive
                  ? toFixDecimals() +
                  " " +
                  NETWORK_DETAILS.CURRENCY_SYMBOL +
                  " " +
                  `($${activePhaseData?.price! /
                  10 ** NETWORK_DETAILS.USD_DECIMALS
                  })`
                  : "Phase not active"}
              </strong>
            </li>
            {/* <li><strong>Date & Time</strong> <strong>{activePhaseData?.isPhaseActive ? convertDatesByTimeZone(activePhaseData?.startTime!, selectedTimeZone) : "Phase not active"} </strong></li> */}
            <li>
              <strong>Date & Time</strong>{" "}
              <strong>
                {concertDetails && selectedTimeZone === TIMEZONE.IN
                  ? concertDetails?.ist_time + " IST"
                  : concertDetails?.utc_time + " UTC"}{" "}
              </strong>
            </li>
          </ul>
          <img src={QRcode} alt="" />
        </div>
        <div className="timezo">
          <div className="left">Select timezone</div>
          <div className="right">
            <button
              type="button"
              onClick={() => setSelectedTimeZone(TIMEZONE.IN)}
              className={selectedTimeZone === TIMEZONE.IN ? "active" : ""}
            >
              IST
            </button>
            <button
              type="button"
              onClick={() => setSelectedTimeZone(TIMEZONE.UK)}
              className={selectedTimeZone === TIMEZONE.UK ? "active" : ""}
            >
              UTC
            </button>
          </div>
        </div>
        <div className="col-12 text-end">
          {errors && errors["timezoneErr"] && (
            <>
              <h5 className="text-danger error-style">
                {" "}
                {errors["timezoneErr"]}{" "}
              </h5>
            </>
          )}
        </div>
        <div className="ticket-no">
          <div className="left">No. of tickes to buy</div>
          <div className="right">
            <button
              className="dec"
              onClick={() =>
                decrementCounter(numberOfTickets, setNumberOfTickets)
              }
            >
              -
            </button>
            <input
              className="ticNo"
              type="text"
              value={numberOfTickets}
              readOnly
            />
            <button
              className="inc"
              onClick={() => {
                incrementCounter(
                  numberOfTickets,
                  setNumberOfTickets,
                  activePhaseData?.phase!,
                  totalPhaseThreeTickets!
                );
                setErrors("");
              }}
            >
              +
            </button>
          </div>
        </div>
        <div className="ticket-no">
          <div className="left">Price to pay (In BNB)</div>
          <div className="right topay">
            {priceToPayInBNB} {NETWORK_DETAILS.CURRENCY_SYMBOL}
          </div>
        </div>
        {/* {
          priceToPayInBNB > 0 && (
            <>
              <div className="ticket-no">
                <div className="left">Price to pay (In BNB)</div>
                <div className="right topay">
                  {priceToPayInBNB} {NETWORK_DETAILS.CURRENCY_SYMBOL}
                </div>
              </div>
            </>
          )
        } */}
        <div className="col-12 text-end">
          {errors && errors["ticketErr"] && (
            <>
              <h5 className="text-danger"> {errors["ticketErr"]} </h5>
            </>
          )}
        </div>
        <div className="ticket-action">
          <Row>
            <Col>
              <ButtonCustom
                title="Cancel"
                className="BorderBtn"
                onClick={handleClose}
                btnIcon={<ArrowIcon />}
              />
            </Col>
            <Col>
              {/* <ButtonCustom title="Buy" onClick={() => { handleOTPModal(); handleClose(); }} btnIcon={<ArrowIcon />} /> */}
              <ButtonCustom
                title="Continue"
                onClick={(event: MouseEvent) => continueHandler(event)}
                btnIcon={<ArrowIcon />}
              />
            </Col>
          </Row>
        </div>
      </TicketModal>
      <TicketModal
        show={showOTP}
        onHide={handleClose}
        className="EmailVerification"
      >
        <div className="ticketConfirmation">
          <h2>Verify Your Email</h2>

          {!isOtpSent ? (
            <>
              <p>The 6 digit code will be sent to your email for verification</p>
          <label htmlFor="email">Email</label>
              <input
                type="email"
                placeholder="Enter email"
                value={buyerEmailId}
                onChange={(e) => setBuyerEmailId(e.target.value)}
              />
            </>
          ) : (
            <>
              <p>The 6 digit code is sent to your email, please check inbox</p>
            </>
          )}
        </div>

        {isOtpSent && (
          <>
            <div className="otpHandle">
              <label htmlFor="otp">Enter OTP</label>
              <OTPInput
                value={OTP}
                onChange={setOTP}
                className="otp_input_root"
                autoFocus
                inputClassName="otp_input"
                OTPLength={6}
                otpType="number"
                disabled={false}
              />
              <div className="otpHandleResend">
                <ResendOTP
                  onResendClick={() => reSendOtpHandler()}
                />
              </div>
            </div>
          </>
        )}

        <div className="ticket-action">
          <Row>
            <Col>
              <ButtonCustom
                title="Cancel"
                className="BorderBtn"
                btnIcon={<ArrowIcon />}
                onClick={handleOTPClose}
              />
            </Col>
            <Col>
              {isOtpSent ? (
                <>
                  {" "}
                  <ButtonCustom
                    title="Verify"
                    btnIcon={<ArrowIcon />}
                    // onClick={() => {
                    //   handleOTPClose();
                    //   handleBuyModalShow();
                    // }}
                    onClick={(e: MouseEvent) => verifyOtpHandler(e)}
                  />
                </>
              ) : (
                <>
                  <ButtonCustom
                    title="Send OTP"
                    btnIcon={<ArrowIcon />}
                    onClick={(e: MouseEvent) => {
                      sendOtpHandler(e)
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
        </div>
      </TicketModal>
      <TicketModal
        show={showBuyModal}
        onHide={handleBuyModal}
        className="TicketConfirmModal"
      >
        <div className="ticketConfirmation">
          <h2>Email Address</h2>
          <p>Ticket bought will be send to your Email Address</p>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            placeholder="Enter email"
            value={buyerEmailId}
            // onChange={(e) => setBuyerEmailId(e.target.value)}
            readOnly
          />
        </div>
        <div className="col-12 text-start">
          {errors && errors["emailErr"] && (
            <>
              <h5 className="text-danger error-style pb-1">
                {" "}
                {errors["emailErr"]}{" "}
              </h5>
            </>
          )}
          <Form.Group className="mb-3 customCheckbox" controlId="TC">
            <Form.Check
              type="checkbox"
              label="I do agree with the Terms and Conditions "
              onChange={handleCheckBoxChange}
            />

          </Form.Group>
          {errors && errors["termsErr"] && (
            <>
              <h5 className="text-danger error-style pt-2">
                {" "}
                {errors["termsErr"]}{" "}
              </h5>
            </>
          )}
        </div>
        <div className="ticket-action">
          <Row>
            <Col>
              <ButtonCustom
                title="Cancel"
                className="BorderBtn"
                onClick={handleBuyModal}
                btnIcon={<ArrowIcon />}
              />
            </Col>
            <Col>
              <ButtonCustom
                title="Buy"
                onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                  buyHandler(event)
                }
                btnIcon={<ArrowIcon />}
              />
            </Col>
          </Row>
        </div>
      </TicketModal>
    </>
  );
};
export default PlatformSec;
