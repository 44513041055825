import React from "react";
import { Accordion, Container, Row, Col } from "react-bootstrap";
import "./FAQs.scss";

const FAQs = () => {
  return (
    <>
      <div className="AboutFaq">
        <Container>
          <div className="real_faq">
            <h2 className="sec_title">Frequently Asked Questions</h2>
            <Accordion>
              <Row>
                <Col md={12}>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <span>1</span> How can you buy tickets for the event?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <strong>Answer:</strong>
                        You can buy tickets by connecting your Decentralised
                        wallet (Metamask, Wallet Connect or Binance Chain
                        Wallet).
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Col>
                <Col md={12}>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <span>2</span> Can one user buy multiple tickets?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <strong>Answer:</strong>
                        Yes, one user can buy multiple tickets. You will receive
                        your tickets on your email address.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Col>
                <Col md={12}>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <span>3</span> Which currency can be used to purchase the
                      ticket ?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <strong>Answer:</strong>
                        You can buy ticket only against crypto i.e. BNB token.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Col>
                <Col md={12}>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <span>4</span> Do you need any external device to connect
                      with this event?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <strong>Answer:</strong>
                        Yes, it is optional to join with a VR device. You can
                        join directly via web too.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Col>
              </Row>
            </Accordion>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FAQs;
