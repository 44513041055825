import { useState } from 'react';
import { Col, Container, Modal, Row, Table } from "react-bootstrap";
import './TicketInfo.scss';
// import Pagination from "../../components/Ui/Pagination/Pagination";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import Paginate from '../../../components/common/CustomPagination/Paginate';
import { API, BNB_DECIMAL, TIMEZONE, TIMEZONE_FILTER } from '../../../config/constants';
import { RESPONSES } from '../../../constants/response';
import { IBuyTickets, ITicketHistory, ITicketsPayload } from '../../../interfaces/common';
import { apiCallGet } from '../../../services/axios';
import DashboardCard from "../../components/Card/DashboardCard/DashboardCard";
// import coin from "../../../assets/SvgIcons/Coin.svg";
import graph from "../../../assets/SvgIcons/Graph.svg";
// import badge from '../../../assets/SvgIcons/Badge.svg';
// import tag from '../../../assets/SvgIcons/tag.svg';
import moment from 'moment';
import Select from 'react-select';
import CopyClipboard from '../../../components/CopyClipboard/CopyClipboard';
import { CommonService } from '../../../services/CommonService';
const TicketInfo = () => {

    const options = [
        { value: TIMEZONE_FILTER.ALL, label: 'All' },
        { value: TIMEZONE_FILTER.IN, label: 'IST' },
        { value: TIMEZONE_FILTER.UK, label: 'UTC' },
    ];

    //   table content
    // const data = [
    //     {
    //         icon: graph,
    //         title: "Sold Tickets (UTC)",
    //         content: "250,000",
    //     },
    //     {
    //         icon: graph,
    //         title: "Sold Tickets (IST)",
    //         content: "110,000",
    //     },
    //     {
    //         icon: graph,
    //         title: "All",
    //         content: 0,
    //     },
    // ];

    const [selectedOption, setSelectedOption] = useState<any>({ value: 3, label: 'All' }); //3 for all result
    //user details from redux
    const userDetails = useSelector((state: any) =>
        state.user.walletAddress ? state.user : false
    );
    //get access token if user is logged in
    const accessToken = useSelector((state: any) =>
        state.user.token ? state.user.token : false
    );
    const [show, setShow] = useState(false);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const handleClose = () => setShow(false);
    const handleShow = (data: any) => {

        if (selectedOption && selectedOption.value < TIMEZONE_FILTER.ALL) {
            const filterArray = [] as unknown as Array<ITicketsPayload>;
            if (selectedOption.value == TIMEZONE_FILTER.IN) {
                data.map((item: ITicketsPayload) => {
                    if (item.timezone_type == TIMEZONE_FILTER.IN) {
                        filterArray.push(item);
                    }
                });
                //setTicketDetails(data)
            }
            if (selectedOption.value == TIMEZONE_FILTER.UK) {
                data.map((item: ITicketsPayload) => {
                    if (item.timezone_type == TIMEZONE_FILTER.UK) {
                        filterArray.push(item);
                    }
                });
            }
            setTicketDetails(filterArray);
        } else {
            setTicketDetails(data)
        }

        setShow(true)
    };
    const [ticketList, setTicketList] = useState<ITicketHistory>();
    const [ticketDetails, setTicketDetails] = useState<Array<ITicketsPayload>>(); // single user ticket details
    useEffect(() => {
        onInIt();
        return () => { }
    }, [])


    useEffect(() => {
        onInIt();
        return () => { }
    }, [selectedOption])
    //Hooks handler
    const onInIt = async () => {
        const filters = selectedOption.value !== 3 ? `?page=${page}&limit=${limit}&tz=${selectedOption.value}` : `?page=${page}&limit=${limit}`
        let result = (await apiCallGet(`${API.TICKETS_HISTORY}${filters}`, {
            headers: {
                "api-access-token": accessToken
            }
        })) as any;
        if (result && result.status === RESPONSES.SUCCESS) {
            setTicketList(result)
        }
    }
    //Pagination handler
    const pageHandler = async (page: number) => {
        //setPage(page);
        const filters = selectedOption.value !== 3 ? `?page=${page}&limit=${limit}&tz=${selectedOption.value}` : `?page=${page}&limit=${limit}`

        let result = (await apiCallGet(`${API.TICKETS_HISTORY}${filters}`, {
            headers: {
                "api-access-token": accessToken
            }
        })) as any;
        if (result && result.status === RESPONSES.SUCCESS) {
            setTicketList(result)
        }
    }



    // console.log('selectedOption', selectedOption);
    // console.log('ticketDetails', ticketDetails)
    return (
        <>
            <section className="TicketHandling">
                <Container fluid>
                    <div className="TicketHandlingFltr">
                        <Row className="justify-content-center">
                            <Col md={12}>
                                <Row>
                                    {/* {data.map((item, index) => (
                                        <Col xs={6} sm={4} md={3} key={index} className="my-30 mt-0">
                                            <DashboardCard
                                                icon={item.icon}
                                                title={item.title}
                                                content={item.content}
                                            />
                                        </Col>
                                    ))} */}
                                    <Col xs={6} sm={4} md={3} className="my-30 mt-0">
                                        <DashboardCard
                                            icon={graph}
                                            title={"Total Tickets Sold"}
                                            content={ticketList?.data.tickets?.totalSoldTickets ? ticketList?.data.tickets?.totalSoldTickets : 0}
                                        />
                                    </Col>
                                    <Col xs={6} sm={4} md={3} className="my-30 mt-0">
                                        <DashboardCard
                                            icon={graph}
                                            title={"Tickets Sold (IST)"}
                                            content={ticketList?.data.tickets?.istSoldTickets ? ticketList?.data.tickets?.istSoldTickets : 0}
                                        />
                                    </Col>
                                    <Col xs={6} sm={4} md={3} className="my-30 mt-0">
                                        <DashboardCard
                                            icon={graph}
                                            title={"Tickets Sold (UTC)"}
                                            content={ticketList?.data.tickets?.utcSoldTickets ? ticketList?.data.tickets?.utcSoldTickets : 0}
                                        />
                                    </Col>
                                    <Col xs={6} sm={12} md={3} className="my-30 mt-0">
                                        <div className="dashboard-card fltr">
                                            <h3 className="mb-2">Filter</h3>
                                            <Select className="customSelect" classNamePrefix="customSelectInner"
                                                defaultValue={selectedOption}
                                                onChange={setSelectedOption}
                                                options={options}
                                            // defaultValue={options[0]}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>
                    <div className="TicketHandlingTable">
                        <div className="table-responsive">
                            <Table striped>
                                <thead>
                                    <tr>
                                        {/* <th>Sr No</th> */}
                                        {/* <th>Wallet Address</th> */}
                                        <th>Email Address</th>
                                        <th>Amount</th>
                                        {/* <th>Time Zone</th> */}
                                        <th>Total Tickets Purchased</th>
                                        <th>Redeem Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticketList && ticketList.data.count > 0 ? <>
                                        {
                                            ticketList.data.rows?.map((item: IBuyTickets, i: number) => (
                                                <tr key={i}>
                                                    {/* <td>
                                                        {(page - 1) * limit + i + 1}
                                                    </td> */}
                                                    {/* <td>{(CommonService.custmizeAddress(item.walletAddress))}<CopyClipboard data={item.walletAddress} /></td> */}
                                                    <td>{item.email}</td>
                                                    <td>{(Number(item.buy_amount) / BNB_DECIMAL).toFixed(4)} BNB</td>
                                                    {/* <td>{Number(item.time_zone_type) == TIMEZONE.IN ? "IST" : "UTC"}</td> */}
                                                    <td>{item.tickets.length}</td>
                                                    <td><Link className="act" to="#" onClick={() => handleShow(item.tickets)} title="View Status">👁</Link></td>
                                                </tr>
                                            ))
                                        }
                                    </> : <tr><td colSpan={6}><strong className="text-white text-center d-block py-5">No record found</strong></td></tr>}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Container>
            </section>
            {ticketList && ticketList.data.count > limit ? <Paginate totalPages={Math.ceil(ticketList.data.count / limit)} callback={pageHandler} /> : null}
            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Redeem Status</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="TicketHandlingTable ModalTable py-xl-4">
                        <div className="table-responsive">
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Ticket Number</th>
                                        <th>Wallet Address</th>
                                        <th>Time Zone</th>
                                        <th>Redeem Status</th>
                                        <th>Date</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {ticketDetails && ticketDetails.length > 0 ?
                                        ticketDetails.map((item: ITicketsPayload, i: number) => (
                                            <>

                                                <tr>
                                                    <td>{item.ticket_no}</td>
                                                    <td>{(CommonService.custmizeAddress(item.wallet_address))}<CopyClipboard data={item.wallet_address} /></td>
                                                    <td>{Number(item.timezone_type) == TIMEZONE.IN ? "IST" : "UTC"}</td>
                                                    <td>{item.is_redeemed == 0 ? "No" : "Yes"}</td>
                                                    <td>{moment(item.purchased_on).format("DD MMM, YYYY HH:MM")}</td>
                                                </tr>
                                            </>
                                        ))
                                        : null}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default TicketInfo