import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonCustom from '../Button/ButtonCustom';
import "./DisconnectModal.scss";

const DisconnectModal = (props: any) => {
    const handleToDisconnect = () => {
        props.callback();
    }
    return (
        <>
            <Modal className='disconnect_modal' show={props.show} onHide={props.viewClose} centered>

                <Modal.Header closeButton>
                    <Modal.Title><h3>Do you want to Disconnect</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4 py-xl-5">
                    <Row className="text-center">
                        <Col><ButtonCustom title="No" className="BorderBtn" onClick={props.viewClose} /></Col>
                        <Col><ButtonCustom title="Yes" onClick={handleToDisconnect} /></Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>

    );
};

export default DisconnectModal;