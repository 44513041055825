import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import iconWalletBinance from '../../../assets/Images/binance.png';
import iconMatamask from "../../../assets/Images/metamask_icon.png";
import iconWallet from "../../../assets/Images/wallet_icon.svg";
import { WALLETS } from "../../../config/constants";
import { RES_MESSAGES } from "../../../constants/messages";
import { useAppDispatch } from "../../../hooks/hooks";
import { IReduxUserDetails } from "../../../interfaces/store";
import { connectToWallet, connectWithBinanceWalletHandler, connectWithWalletConnect } from "../../../redux/actions/connect.action";
import Toast from "../Toast";
import "./ConnectWallet.scss";





const ConnectWallet = ({ show, handleClose }: any) => {
  const dispatch = useAppDispatch();
  // const [isRefresh, setIsRefresh] = useState(true);

  const userDetails: IReduxUserDetails = useSelector((state: any) => (state.user.walletAddress) ? state.user : false);

  //Handler function to connect to metamask wallet 
  const connectWithMetaMaskHandler = async (e: any) => { 
    e.preventDefault(); 
    let result = await dispatch(connectToWallet(WALLETS.METAMASK));
    if (result) {
      Toast.success(RES_MESSAGES.WALLET_CONNECTED);
    } 
  };

  //Handler function to connect using wallet connect
  const walletConnectHandler = async (e: React.MouseEvent) => {
    e.preventDefault();

    let result: any = await dispatch(
      connectWithWalletConnect(WALLETS.WALLET_CONNECT)
    );
    if (result) {
      Toast.success(RES_MESSAGES.WALLET_CONNECTED);
    }
  };

    //Handler function to connect to metamask wallet 
  const connectWithBinanceWallet = async (e: any) => { 
    e.preventDefault(); 
    let result = await dispatch(connectWithBinanceWalletHandler(WALLETS.BINANCE_WALLET));
    if (result) {
      Toast.success(RES_MESSAGES.WALLET_CONNECTED);
    } 
  };
  //Function to disconnect wallet
  // const disconnectHandler = async (e: React.MouseEvent) => {
  //   e.preventDefault();
  //   dispatch(disconnectWallet(userDetails?.wallet));
  // };

  return (
    <Modal
      scrollable={true}
      className="connect_wallet"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{userDetails && userDetails?.walletAddress ? "Disconnect Wallet" : "Connect to a wallet"}</h3>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="connect_options">
            <ul>
              {
                isMobile ? null : (<>
                  <li>
                    <Button onClick={(e) => connectWithMetaMaskHandler(e)}>
                      MetaMask{" "}
                      <span>
                        <img src={iconMatamask} alt="metamask-img" />
                      </span>{" "}
                    </Button>
                  </li>
                </>)
              }

              <li>
                <Button onClick={e => walletConnectHandler(e)}>
                  WalletConnect{" "}
                  <span>
                    <img src={iconWallet} alt="wallet-connect-img" />
                  </span>{" "}
                </Button>
              </li>
              {
                isMobile ? null : (<>
                  <li>
                    <Button onClick={e => connectWithBinanceWallet(e)}>
                      Binance Chain{" "}
                      <span>
                        <img src={iconWalletBinance} alt="wallet-connect-img" />
                      </span>{" "}
                    </Button>
                  </li>
                </>)
              }

            </ul>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConnectWallet;
