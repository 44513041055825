import "./DashboardCard.scss";

const DashboardCard = ({ title, content, icon }: any) => {
  return (
    <div className="dashboard-card">
      <div className="dashboard-card__icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="dashboard-card__content">
        <h3 className="mb-2">{title}</h3>
        <h2>{content}</h2>
      </div>
    </div>
  );
};

export default DashboardCard;
