import Web3 from "web3";
import { DYNAMIC_ABI } from "../../abis/dynamic.abi";
import { ALLOWANCE_MAXLIMIT, CONTRACT, WALLETS } from "../../config/constants";
import {
  IBuyTicketRequest,
  IProvideApprovalPayload
} from "../../interfaces/user";
import { provider } from "../walletConnect/provider";
import { NETWORK_DETAILS } from "./../../config/constants";
import { ContractInstanceHandler } from "./../InstanceService";

// get balance method
export const getETHBalanceMethod = async (walletAddress: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { ethereum } = window as any;
      var balance;
      let web3 = new Web3(ethereum);
      balance = await web3.eth.getBalance(walletAddress);
      resolve(balance);
    } catch (error) {
      console.error("Error on getETHBalanceMethod", error);
      reject(error);
    }
  });
};

//Method to  provide approval
export const provideApproval = async (
  walletType: string,
  data: IProvideApprovalPayload
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let limit, approval;
      limit = ALLOWANCE_MAXLIMIT.toLocaleString("fullwide", {
        useGrouping: false,
      });
      let contractAddress, contractInstance;

      if (walletType === WALLETS.WALLET_CONNECT) {
        const instance = new Web3(provider);
        contractInstance = new instance.eth.Contract(DYNAMIC_ABI, data.address);
      } else if (walletType === WALLETS.METAMASK) {
        const { ethereum } = window as any;
        const instance = new Web3(ethereum);
        contractInstance = new instance.eth.Contract(DYNAMIC_ABI, data.address);
      }
      //Contract to receive approval
      contractAddress = CONTRACT.KOOPVERSE_CONTACT_ADDRESS;

      let gas = await contractInstance?.methods
        .approve(contractAddress, limit)
        .estimateGas({ from: data.walletAddress });

      approval = await contractInstance?.methods
        .approve(contractAddress, limit)
        .send({ from: data.walletAddress, gas: gas });
      resolve(approval);
    } catch (error) {
      console.error("Error while providing approval", error);
      reject(error);
    }
  });
};

//Method to get allowance
export const getAllowanceInfo = async (
  walletType: string,
  data: IProvideApprovalPayload
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contractInstance;
      if (walletType === WALLETS.WALLET_CONNECT) {
        const instance = await new Web3(provider);
        contractInstance = new instance.eth.Contract(DYNAMIC_ABI, data.address);
      } else if (walletType === WALLETS.METAMASK) {
        const { ethereum } = window as any;
        const instance = await new Web3(ethereum);
        contractInstance = new instance.eth.Contract(DYNAMIC_ABI, data.address);
      }
      const contractAddress = CONTRACT.KOOPVERSE_CONTACT_ADDRESS as string;
      let allowance = await contractInstance?.methods
        .allowance(data.walletAddress, contractAddress)
        .call();
      resolve(allowance);
    } catch (error) {
      console.error("Error while getting allowance", error);
      reject(error);
    }
  });
};

/**
 * Method to get current active phase from contract
 * @param walletType
 * @returns
 */
export const getCurrentPhaseMethod = (walletType: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      const currentACtivePhase = (await contract?.methods
        .getCurrentPhase()
        .call()) as number;
      resolve(currentACtivePhase);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Method to get ticket price in BNB
 * @param walletType
 * @returns
 */
export const getPerTicketPriceInBNBMethod = (walletType: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      //current active phase
      const currentActivePhase = (await contract?.methods
        .getCurrentPhase()
        .call()) as number;
      //ticket price
      const ticketPrice = (await contract?.methods
        .perTicketPriceInBNB(currentActivePhase)
        .call()) as number;
      const price = ticketPrice / 10 ** NETWORK_DETAILS.BNB_DECIMALS;
      resolve(price);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Method to buy tickets
 * @param walletType
 * @param data
 * @returns
 */
export const buyTicketMethod = (walletType: string, data: IBuyTicketRequest) => {
  return new Promise(async (resolve, reject) => {
    console.log("TICKET_PRICE", data);
    try {
      const contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      //calculate gas price
      const gas = await contract?.methods
        .buyTicket(data.ticketsToBuy, data.email, data.timeZoneType)
        .estimateGas({
          from: data.walletAddress,
          value: data.amountToPay,
        });
      //interact with contract
      await contract.methods
        .buyTicket(data.ticketsToBuy, data.email, data.timeZoneType)
        .send({
          from: data.walletAddress,
          gas,
          value: data.amountToPay,
        })
        .on("confirmation", () => {})
        .then((res: any) => {
          resolve(res);
        });
    } catch (error) {
      console.error("Error while buying tickets", error);
      reject(error);
    }
  });
};

/**
 * Get details of activePhase
 * @param walletType
 * @returns
 */
export const getActivePhaseInfoMethod = (walletType: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      //current active phase
      const currentActivePhase = (await contract?.methods
        .getCurrentPhase()
        .call()) as number;
      //active phase info
      const activePhaseInfo = await contract?.methods
        .phase(currentActivePhase)
        .call();
      //current ticket price
      const ticketPrice = (await contract?.methods
        .perTicketPriceInBNB(currentActivePhase)
        .call()) as number;
      const data = {
        endTime: activePhaseInfo.endTime,
        price: activePhaseInfo.price,
        startTime: activePhaseInfo.startTime,
        ticketSold: activePhaseInfo.ticketSold,
        ticketPrice,
        phase: currentActivePhase,
      };
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Method to get ticket price user needs to pay in BNB
 * @param walletType 
 * @param tickets 
 * @returns 
 */
export const getTotalPriceToPayMethod = (walletType: string, tickets:number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      //current active phase
      const currentActivePhase = (await contract?.methods
        .getCurrentPhase()
        .call()) as number;
      //ticket price
      const priceToPay = (await contract?.methods
        .getTotalPrice(currentActivePhase, tickets)
        .call()) as number;
      resolve(priceToPay);
    } catch (error) {
      reject(error);
    }
  });
};


