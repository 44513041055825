import * as async from 'async';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from "react-bootstrap";
import DateTimePicker from 'react-datetime-picker';
import { useSelector } from 'react-redux';
import ButtonCustom from "../../../components/common/Button/ButtonCustom";
import Toast from '../../../components/common/Toast';
import { API, PHASE } from '../../../config/constants';
import { RES_MESSAGES } from '../../../constants/messages';
import { RESPONSES } from '../../../constants/response';
import { isOwner } from '../../../handlers/common.handler';
import { useAppDispatch } from '../../../hooks/hooks';
import { ISinglePhaseResponse } from '../../../interfaces/common';
import { IReduxUserDetails } from '../../../interfaces/store';
import { IConcertDetailsResponse } from '../../../interfaces/user';
import { setPhasesTimeAction, singlePhaseInfoAction } from '../../../redux/actions/admin.action';
import { setLoader } from '../../../redux/actions/loader.action';
import * as actionTypes from "../../../redux/actionTypes";
import { apiCallGet, apiCallPost } from '../../../services/axios';
import './TimeManagement.scss';

const TimeManagement = () => {
    const dispatch = useAppDispatch();
    const userDetails: IReduxUserDetails = useSelector((state: any) => (state.user.walletAddress) ? state.user : false);
    const [phaseOneStartTime, setPhaseOneStartTime] = useState<Date>(new Date());;
    const [phaseOneEndTime, setPhaseOneEndTime] = useState(new Date());
    const [phaseTwoStartTime, setPhaseTwoStartTime] = useState(new Date());
    const [phaseTwoEndTime, setPhaseTwoEndTime] = useState(new Date());
    const [phaseThreeStartTime, setPhaseThreeStartTime] = useState(new Date());
   // const [phaseThreeEndTime, setPhaseThreeEndTime] = useState(new Date());
    const [Concertvalue, ConcertOnchange] = useState(new Date());
    const [Timevalue, TimeOnchange] = useState(new Date());
    const [concertName, setConcertName] = useState('');
    const [concertDateTime, setConcertDateTime] = useState(new Date());
    const [concertDateTimeInIst, setConcertDateTimeInIst] = useState(new Date());
    const [concertDetails, setConcertDetails] = useState<IConcertDetailsResponse>();


    useEffect(() => {
        onInit();
        return () => { }
    }, []);


    const onInit = async () => {
        //Using Callbacks
        try {
            dispatch(setLoader(true));
            await async.parallel({
                phaseOneDetails: function (callback) {
                    dispatch(singlePhaseInfoAction(userDetails?.wallet, PHASE.ONE)).then((response: ISinglePhaseResponse | void) => {
                        const unixStartTime = moment.unix(response?.startTime!).utc();
                        const localStartDate = moment(unixStartTime).local().toDate();
                        const unixEndTime = moment.unix(response?.endTime!).utc();
                        const localEndDate = moment(unixEndTime).local().toDate();
                        setPhaseOneStartTime(localStartDate);
                        setPhaseOneEndTime(localEndDate);
                        callback(null, response);
                    }).catch((err: any) => {
                        console.log('err', err);
                    })
                },
                phaseTwoDetails: function (callback) {
                    dispatch(singlePhaseInfoAction(userDetails?.wallet, PHASE.TWO)).then((response: ISinglePhaseResponse | void) => {
                        const unixStartTime = moment.unix(response?.startTime!).utc();
                        // const localStartDate = moment(unixStartTime).local().toDate();
                        const localStartDate = moment(unixStartTime).toDate();

                        const unixEndTime = moment.unix(response?.endTime!).utc();
                        const localEndDate = moment(unixEndTime).local().toDate();
                        setPhaseTwoStartTime(localStartDate);
                        setPhaseTwoEndTime(localEndDate);
                        callback(null, response);
                    }).catch((err: any) => {
                        console.log('err', err);
                    })
                },
                phaseThreeDetails: function (callback) {
                    dispatch(singlePhaseInfoAction(userDetails?.wallet, PHASE.THREE)).then((response: ISinglePhaseResponse | void) => {
                        const unixStartTime = moment.unix(response?.startTime!).utc();
                        const localStartDate = moment(unixStartTime).local().toDate();
                        setPhaseThreeStartTime(localStartDate);
                        callback(null, response);
                    }).catch((err: any) => {
                        console.log('err', err);
                    })
                },
                concertDetails: function (callback) {
                    apiCallGet(API.CONCERT_DETAILS).then((response: AxiosResponse | any) => {
                        if (response.data.length > 0 && response.status === RESPONSES.SUCCESS) {
                            setConcertDetails(response.data[0]);
                            const data = response.data[0];
                            setConcertName(data?.concert_name);
                            //set date in UTC
                            var myDate = moment(data?.utc_time, 'MM/DD/YYYY hh:mm A').unix();
                            //console.log('myDate', myDate)
                            const unixStartTime = moment.unix(myDate).utc();
                            const localStartDate = moment(unixStartTime).local().toDate();
                          //  console.log('localStartDate', localStartDate)
                            //set datetime in ITC
                            var iSTDateTime = moment(data?.ist_time, 'MM/DD/YYYY hh:mm A').unix();
                            // console.log('iSTDateTime', iSTDateTime)
                            const unixIstStartTime = moment.unix(iSTDateTime).utc();
                            //  console.log('unix', unixIstStartTime)
                            const localISTStartDate = moment(unixIstStartTime).local().toDate();
                            //console.log('localISTStartDate', localISTStartDate)

                            setConcertDateTime(localStartDate);
                            setConcertDateTimeInIst(localISTStartDate);

                        }

                        callback(null, response);
                    }).catch((err: any) => {
                        console.log('err', err);
                    })
                },
            });
            dispatch(setLoader(false));
            //console.log(results);
            // results is equal to ['one','two'] even though
            // the second function had a shorter timeout.
        }
        catch (err) {
            dispatch(setLoader(false));
            console.log(err);
        }
    }
    
    
    
    //Function to add or update concert details
    const addAndUpdateConcertDetails = async (event: MouseEvent) => {
        event.preventDefault();
        const isValidOwner = await dispatch(isOwner(userDetails.walletAddress, userDetails.wallet));
        if (typeof isValidOwner === 'undefined') {
            return;
        }
        

       // const unixTs = moment.utc(concertDateTime).unix()
        // const utc_time = moment.utc(concertDateTime).format("MM/DD/YYYY hh:mm A");
        //console.log('concertDateTime', moment.utc(concertDateTime).unix())

        // const utc_time = moment(concertDateTime).utc().format("MM/DD/YYYY hh:mm A");
        const utc_time = moment(concertDateTime)
            //.unix(unixTs)
            .tz("Asia/Kolkata")
            .format("MM/DD/YYYY hh:mm A");

        const ist_time = moment(concertDateTimeInIst)
            //.unix(unixTs)
            .tz("Asia/Kolkata")
            .format("MM/DD/YYYY hh:mm A");
        let payload = {
            "concert_name": concertName,
            "ist_time": ist_time,
            "utc_time": utc_time,
        }
        
        let result: any = (await apiCallPost(API.ADD_UPDATE__DETAILS, payload, {
            headers: {
                "api-access-token": userDetails.token
            }
        }));
        if (result && result.status == RESPONSES.SUCCESS) {
            dispatch({ type: actionTypes.CONCERT, payload: result.data[0] })
            Toast.success(RES_MESSAGES.CONCERT_SUCCESS)
        }
        onInit();
    }

    // const onInit = async () => {
    //     dispatch(setLoader(true));
    //     const phaseOneInfo = (await dispatch(singlePhaseInfoAction(userDetails?.wallet, PHASE.ONE)));
    //     const phaseTwoInfo = (await dispatch(singlePhaseInfoAction(userDetails?.wallet, PHASE.TWO)));
    //     const phaseThreeInfo = (await dispatch(singlePhaseInfoAction(userDetails?.wallet, PHASE.THREE)));
    //     //api to get concert details
    //     const concertDetails = (await apiCallGet(API.CONCERT_DETAILS)) as AxiosResponse;

    //     if (phaseOneInfo) {
    //         const unixStartTime = moment.unix(phaseOneInfo?.startTime).utc();
    //         const localStartDate = moment(unixStartTime).local().toDate();
    //         const unixEndTime = moment.unix(phaseOneInfo?.endTime).utc();
    //         const localEndDate = moment(unixEndTime).local().toDate();
    //         setPhaseOneStartTime(localStartDate);
    //         setPhaseOneEndTime(localEndDate);
    //     }
    //     if (phaseTwoInfo) {
    //         const unixStartTime = moment.unix(phaseTwoInfo?.startTime).utc();
    //         const localStartDate = moment(unixStartTime).local().toDate();
    //         const unixEndTime = moment.unix(phaseTwoInfo?.endTime).utc();
    //         const localEndDate = moment(unixEndTime).local().toDate();
    //         setPhaseTwoStartTime(localStartDate);
    //         setPhaseTwoEndTime(localEndDate);
    //     }
    //     if (phaseThreeInfo) {
    //         const unixStartTime = moment.unix(phaseThreeInfo?.startTime).utc();
    //         const localStartDate = moment(unixStartTime).local().toDate();
    //         setPhaseThreeStartTime(localStartDate);

    //     }

    //     if (concertDetails.status === RESPONSES.SUCCESS) {
    //         setConcertDetails(concertDetails.data[0]);
    //         const data = concertDetails.data[0];
    //         setConcertName(data?.concert_name);
    //         //set date in UTC
    //         var myDate = moment(data?.utc_time, 'MM/DD/YYYY HH:mm A').unix();
    //         const unixStartTime = moment.unix(myDate).utc();
    //         const localStartDate = moment(unixStartTime).local().toDate();
    //         //set datetime in ITC
    //         var iSTDateTime = moment(data?.ist_time, 'MM/DD/YYYY HH:mm A').unix();
    //         console.log('iSTDateTime', iSTDateTime)
    //         const unixIstStartTime = moment.unix(iSTDateTime).utc();
    //         console.log('unix', unixIstStartTime)
    //         const localISTStartDate = moment(unixIstStartTime).local().toDate();
    //         console.log('localISTStartDate', localISTStartDate)
    //         setConcertDateTime(localStartDate);
    //         setConcertDateTimeInIst(localISTStartDate);

    //     }
    //     dispatch(setLoader(false));
    // }


    const setPhaseTimeHandler = async (event: MouseEvent) => {
        event.preventDefault();
        dispatch(setLoader(true));
        const isValidOwner = await dispatch(isOwner(userDetails?.walletAddress, userDetails.wallet));
        if (typeof isValidOwner === 'undefined') {
            dispatch(setLoader(false));
            return;
        }
        const startTimePayload =
            [moment(phaseOneStartTime).utc().unix(), moment(phaseTwoStartTime).utc().unix(), moment(phaseThreeStartTime).utc().unix()];
        const endTimePayload = [moment(phaseOneEndTime).utc().unix(), moment(phaseTwoEndTime).utc().unix(), 0];

        let result = await dispatch(
            setPhasesTimeAction(userDetails.wallet, {
                startTimePayload,
                endTimePayload,
                walletAddress: userDetails.walletAddress,
            })
        );
        if (result && result.status === true) {
            Toast.success(RES_MESSAGES.BUY_SUCCESS);
        }
        dispatch(setLoader(false));

        onInit();
    }


     return (
        <>
            <div className="TimeMng">
                <Container fluid>
                    <div className="TimeMngbox">
                        <h2>Phase 1</h2>
                        <Row>
                            <Col md={6}>
                                <label>
                                    Start Time
                                </label>
                                 <DateTimePicker value={phaseOneStartTime} onChange={date => date && setPhaseOneStartTime(date)} />
                                {/*for future use <DatePicker value={PhsOneStartvalue} onChange={PhsOneStart} /> */}
                            </Col>
                            <Col md={6}>
                                <label>
                                    End Time
                                </label>
                                 <DateTimePicker value={phaseOneEndTime} onChange={date => date && setPhaseOneEndTime(date)} />
                            </Col>
                        </Row>
                        <h2 className="mt-5">Phase 2</h2>
                        <Row>
                            <Col md={6}>
                                <label>
                                    Start Time
                                </label>
                                 <DateTimePicker value={phaseTwoStartTime} onChange={date => date && setPhaseTwoStartTime(date)} />
                            </Col>
                            <Col md={6}>
                                <label>
                                    End Time
                                </label>
                                 <DateTimePicker value={phaseTwoEndTime} onChange={date => date && setPhaseTwoEndTime(date)} />
                            </Col>
                        </Row>
                        <h2 className="mt-5">Phase 3</h2>
                        <Row>
                             <Col md={12}>
                                <label>
                                    Start Time
                                </label>
                                 <DateTimePicker value={phaseThreeStartTime} onChange={date => date && setPhaseThreeStartTime(date)} />
                            </Col>
                             {/* <Col md={6}>
                                <label>
                                    End Time
                                </label>
                                <DateTimePicker value={PhsThreeEndvalue} onChange={PhsThreeEnd} />
                            </Col> */}
                        </Row>
                    </div>

                     <ButtonCustom title="Submit" className="mt-4" onClick={(e: MouseEvent) => setPhaseTimeHandler(e)} />
                </Container>
             </div>
            <div className="TimeMng mt-4">
                <Container fluid>
                    <div className="TimeMngbox">
                        <h2>Concert Management</h2>
                        <Row>
                             <Col md={4}>
                                <label>
                                Concert Name
                                </label>
                                <div className="input__wrap mt-3">
                                     <Form.Control type="text" className="pe-5" placeholder="Enter concert name here" value={concertName} onChange={(e) => setConcertName(e.target.value)} />
                                </div>
                            </Col>
                             <Col md={4}>
                                <label>
                                     Set Date & Time (UTC)
                                </label>
                                 <DateTimePicker format="dd/MM/yyyy hh:mm a" value={concertDateTime} onChange={date => date && setConcertDateTime(date)} />
                             </Col>
                             <Col md={4}>
                                 <label>
                                     Set Date & Time (IST)
                                 </label>
                                 {/* <DateTimePicker format="dd/MM/yyyy hh:mm a" value={concertDateTimeInIst} onChange={date => date && setConcertDateTimeInIst(date)} /> */}
                                 <DateTimePicker format="dd/MM/yyyy hh:mm a" value={concertDateTimeInIst} onChange={date => date && setConcertDateTimeInIst(date)} />

                             </Col>
                            {/* <Col md={6}  className="mb-3">
                                <label>
                                Set Time
                                </label>
                                <DateTimePicker value={Timevalue} onChange={TimeOnchange} className="remove-date" />
                             
                            </Col> */}
                        </Row>

                    </div>

                     <ButtonCustom type="button" title="Update" className="mt-5" onClick={(e: MouseEvent) => addAndUpdateConcertDetails(e)} />
                </Container>
            </div>
        </>
    )
}
export default TimeManagement