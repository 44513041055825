import { useEffect, useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BarsIcon, BarsOpenIcon } from "../../../assets/SvgIcons/SvgIcons";
import ButtonCustom from "../../../components/common/Button/ButtonCustom";
import ConnectWallet from "../../../components/common/ConnectWallet";
import DisconnectModal from "../../../components/common/DisconnectModal/DisconnectModal";
import Toast from "../../../components/common/Toast";
import { useAppDispatch } from "../../../hooks/hooks";
import { IReduxUserDetails } from "../../../interfaces/store";
import { disconnectWallet } from "../../../redux/actions/connect.action";
import { CommonService } from "../../../services/CommonService";
import "./AdminHeader.scss";
const AdminHeader = ({ sideBarToggle }: any) => {
  // const AdminHeader = () => {
  const dispatch = useAppDispatch();
  const userDetails: IReduxUserDetails = useSelector((state: any) => (state.user.walletAddress) ? state.user : false);
  // location based Page Heading
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/admin");
  // location based Page Heading
  const { collapseSidebar } = useProSidebar();
  const [toggleBtn, settoggleBtn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isActive, setActive] = useState(false);
  const [view, setView] = useState(false);
  const viewClose = () => setView(false);
  const viewShow = () => setView(true);
  // const ToggleHandle = () => {
  //   settoggleBtn(!toggleBtn);
  // }
  useEffect(() => {
    setShow(false);
  }, [userDetails?.walletAddress]);
  // mobile menu close on click menus
  useEffect(() => {
    if (window.innerWidth < 1200) {
      // alert("window width less 1200 working")
      if (sideBarToggle) {
        collapseSidebar();
      }
      else {
      }
    }
  }, [sideBarToggle]);
  // end of  mobile menu close on click menus
  // useEffect(() => {
  //   if(sideBarToggle){
  //     collapseSidebar();
  //   }
  // }, [sideBarToggle]);
  const showSidebar = () => {
    const sidebar = document.querySelector(".admin-layout, .tglBtn");
    if (sidebar?.classList.contains("expanded")) {
      sidebar?.classList.remove("expanded");
    } else {
      sidebar?.classList.add("expanded");
    }
    settoggleBtn(!toggleBtn);
  };
  //Function to disconnect wallet
  const disconnectHandler = async () => {
    dispatch(disconnectWallet(userDetails?.wallet));
    Toast.success("Disconnected successfully");
    setView(false);
  };
  return (
    <>
      <div className="admin-header d-flex justify-content-between align-items-center">
        <h2 className="mb-0">
          {splitLocation[1] === "" || splitLocation[1] === "/" ? "Dashboard" : ""}
          {splitLocation[1] === "/dashboard" ? "Dashboard" : ""}
          {splitLocation[1] === "/ticket-info" ? "Ticket Info" : ""}
          {splitLocation[1] === "/time-management" ? "Time Management" : ""}
          {splitLocation[1] === "/price-management" ? "Price Management" : ""}
          {splitLocation[1] === "/setting" ? "Setting" : ""}
        </h2>
        {
          !userDetails && (<div className="h4 text-warning">You need to connect to admin wallet address in order to update anything</div>)
        }

        <div className="right-actions">
          {/* <ButtonCustom title="Conncet Wallet" /> */}
          {
            userDetails && userDetails?.walletAddress ? (
              <>
                <ButtonCustom
                  className="headerBtn"
                  title={
                    `${CommonService.custmizeAddress(userDetails?.walletAddress)}`
                  }
                  onClick={(e: any) => {
                    setView(true);
                  }
                    // disconnectHandler(e)
                  }
                />
              </>
            ) : (
              <>
                <ButtonCustom
                  className="headerBtn"
                  title={
                    "Connect Wallet"
                  }
                  onClick={() => {
                    handleShow();
                  }}
                />
                {/* <ButtonCustom title="Disconnect" /> */}
              </>
            )
          }
          <button className="tglBtn" onClick={() => { collapseSidebar(); showSidebar(); }}>
            {toggleBtn ? <BarsOpenIcon /> : <BarsIcon />}
          </button>
        </div>
      </div>
      <DisconnectModal
        show={view}
        viewShow={viewShow}
        viewClose={viewClose}
        callback={disconnectHandler}
      />
      <ConnectWallet
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
      />
    </>
  );
};
export default AdminHeader;
