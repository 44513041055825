
import { Spinner } from 'react-bootstrap'
import { useSelector } from "react-redux";
import "./style.scss"


function LoaderComponent() {
  const { isLoading } = useSelector((state:any) => state.loader);
  if (isLoading) {
    return (
      <div className="overlayloader">
        <Spinner animation="border" variant="light" className="loader-style" />

      </div>
    );
  } else {
    return <div></div>;
  }
}

export default LoaderComponent;
