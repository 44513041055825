import React from "react";
import { Accordion } from "react-bootstrap";
import "./AboutFAQ.scss";

const AboutFAQ = () => {
  return (
    <>
      <div className="AboutFaq">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>INTRODUCTION</Accordion.Header>
            <Accordion.Body>
              <p>
                Shubhdeep Singh Sidhu, better known by his stage name{" "}
                <strong>SIDHU MOOSE WALA</strong> was an Indian rapper, singer
                and songwriter born in Moosa, Mansa district.
              </p>
              <p>
                He was born in a Jatt Sikh family, to Balkaur Singh (Father) and
                Charan Kaur (Mother). He studied at the Guru Nanak Dev
                Engineering College, Ludhiana and graduated in Electrical
                Engineering in 2016
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>CAREER AND ACHIEVEMENTS </Accordion.Header>
            <Accordion.Body>
              <p>
                Moose Wala started his career in 2016 as a songwriter, for the
                song "License" by Ninja, and as a singer in 2017 with a duet
                song, <strong>"G WAGON"</strong>. Following his debut, he has
                collaborated with Brown Boyz for various tracks. One of his
                major achievements was that ten of his most popular songs have
                touched the peak spots at UK Asian Chart, while two of them have
                been ranked to the top of the chart. Also, another song{" "}
                <strong>“BAMBIHA BOLE”</strong> positioned at the top five on
                the Global YouTube Music Chart.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>POLITICS</Accordion.Header>
            <Accordion.Body>
              <p>
                Moose Wala actively campaigned for his Mother Charan Kaur, who
                has won the Sarpanch election from Moosa village in December
                2018. On December 03, 2021, Moose Wala joined the Indian
                National Congress to contest the 2022 Punjab Legislative
                Assembly election. On April 11, 2022, Moose Wala released a song
                with title <strong>"SCAPEGOAT"</strong>, in which he laments his
                failure in the 2022 Punjab State Assembly elections.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>DEATH</Accordion.Header>
            <Accordion.Body>
              <p>
                Moose Wala was shot dead by unidentified assailants in his car
                on <strong>MAY 29, 2022</strong>, in Jawaharke village of Mansa
                district. According to the Police sources, it was an
                international gang that has initially claimed the responsibility
                for the murder through an unverified Facebook post, that was
                consistently denied by their leader for making though, but he
                was being held by the Punjab Police as of June 2022, and is
                considered the "mastermind" of the murder by the officials.{" "}
              </p>
              <p>
                <strong>THE INCIDENT:</strong> According to the Police, at
                around 4:30 PM, Sidhu Moose Wala left his house with his cousin
                and neighbour. Moose Wala was driving his black SUV. He was
                going to his aunt's house in Barnala. At 5:30 PM, when the SUV
                reached the village Jawaharke, two other cars have intercepted
                and blocked it. Thirty rounds were fired during the incident,
                and the other two men were also injured. For his self-defence,
                Moose Wala replied with bravery and courage to the attackers by
                using his pistol, but……. After the shootout, the attackers have
                left an agonizing moment for all of us. Moose Wala was declared
                dead at the Civil Hospital in Mansa.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default AboutFAQ;
