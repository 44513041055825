import Toast from "../../components/common/Toast";
import {
  IDashboardResponse,
  IPhasesPricePayload,
  ISetPhasePrice,
  ISetPhasesTime,
  ISetPhaseThreeMaxTickets,
  ISinglePhaseResponse,
} from "../../interfaces/common";
import { IOwnershipTransfer } from "../../interfaces/user";
import { IContractSendTxnConfirmResponse } from "./../../interfaces/user";
import { CommonService } from "./../../services/CommonService";
import {
  getDashBoardInfoMethod,
  getOwnerAddressMethod,
  getPhasesPriceInBNBMethod,
  getReceiverAddress,
  getSinglePhaseInfoMethod,
  getTotalPhaseThreeTicketsMethod,
  setAllPhasesTimeMethod,
  setPhaseThreeMaxTicketsMethod,
  setPriceInPhaseMethod,
  updateOwnerAddressMethod,
  updateReceiverAddressMethod,
} from "./../../services/methods/adminMethod";
import { AppDispatch } from "./../store";
import { setLoader } from "./loader.action";

//Action to update owner address
export const toUpdateOwnerAddress = (
  walletType: string,
  data: IOwnershipTransfer
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoader(true));
      let result = (await updateOwnerAddressMethod(
        walletType,
        data
      )) as IContractSendTxnConfirmResponse;
      dispatch(setLoader(false));
      return result;
    } catch (error) {
      //  console.error('Error under toUpdateOwnerAddress ', error);
      dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
      //return error;
    }
  };
};

//Action to get receiver address
export const actionGetReceiverAddress =
  (walletType: string) =>
  async (dispatch: AppDispatch): Promise<string | void> => {
    try {
      let result;
      // dispatch(setLoader(true));
      result = (await getReceiverAddress(walletType)) as string;
      //dispatch(setLoader(false));
      return result;
    } catch (error: any) {
      // dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
  };

//Action to update receiver address
export const toUpdateReceiverAddress = (
  walletType: string,
  data: IOwnershipTransfer
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoader(true));
      let result = (await updateReceiverAddressMethod(
        walletType,
        data
      )) as IContractSendTxnConfirmResponse;
      dispatch(setLoader(false));
      return result;
    } catch (error) {
      dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
  };
};

//Action to get contract owner address
export const getOwnerAddressAction =
  (walletType: string) =>
  async (dispatch: AppDispatch): Promise<string | void> => {
    try {
      let result;
      // dispatch(setLoader(true));
      result = (await getOwnerAddressMethod(walletType)) as string;
      //dispatch(setLoader(false));
      return result;
    } catch (error: any) {
      // dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
  };

//Action to get contract owner address
export const getPhasesPriceAction =
  (walletType: string) =>
  async (dispatch: AppDispatch): Promise<IPhasesPricePayload | void> => {
    try {
      let result;
      // dispatch(setLoader(true));
      result = (await getPhasesPriceInBNBMethod(
        walletType
      )) as IPhasesPricePayload;
      //dispatch(setLoader(false));
      return result;
    } catch (error: any) {
      // dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
  };

//Action to set phase price
export const setPriceAction = (walletType: string, data: ISetPhasePrice) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoader(true));
      let result = (await setPriceInPhaseMethod(
        walletType,
        data
      )) as IContractSendTxnConfirmResponse;
      dispatch(setLoader(false));
      return result;
    } catch (error) {
      dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
  };
};


//Action to get contract single phase info
export const singlePhaseInfoAction =
  (walletType: string, phase: number) =>
  async (dispatch: AppDispatch): Promise<ISinglePhaseResponse | void> => {
    try {
      let result;
      // dispatch(setLoader(true));
      result = (await getSinglePhaseInfoMethod(
        walletType,
        phase
      )) as ISinglePhaseResponse;
      //dispatch(setLoader(false));
      return result;
    } catch (error: any) {
      // dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
    };
  

//Action to set phase three maximum tickets
export const setPhaseThreeMaxTicketAction = (
  walletType: string,
  data: ISetPhaseThreeMaxTickets
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoader(true));
      let result = (await setPhaseThreeMaxTicketsMethod(
        walletType,
        data
      )) as IContractSendTxnConfirmResponse;
      dispatch(setLoader(false));
      return result;
    } catch (error) {
      dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
  };
};



//Action to get max number of phase three tickets to be sold
export const getPhaseThreeTotalTicketsToSoldAction =
  (walletType: string) =>
  async (dispatch: AppDispatch): Promise<number | void> => {
    try {
      let result;
      // dispatch(setLoader(true));
      result = (await getTotalPhaseThreeTicketsMethod(walletType)) as number;
      //dispatch(setLoader(false));
      return result;
    } catch (error: any) {
      // dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
  };

//Action to get max number of phase three tickets to be sold
export const dashboardInfoAction =
  (walletType: string) =>
  async (dispatch: AppDispatch): Promise<IDashboardResponse | void> => {
    try {
      let result;
      // dispatch(setLoader(true));
      result = (await getDashBoardInfoMethod(walletType)) as IDashboardResponse;
      //dispatch(setLoader(false));
      return result;
    } catch (error: any) {
      // dispatch(setLoader(false));
      return Toast.error(CommonService.getError(error));
    }
  };
  
    //Action to set phases time
    export const setPhasesTimeAction = (walletType: string, data: ISetPhasesTime) => {
      return async (dispatch: AppDispatch) => {
        try {
          // dispatch(setLoader(true));
          let result = (await setAllPhasesTimeMethod(
            walletType,
            data
          )) as IContractSendTxnConfirmResponse;
          // dispatch(setLoader(false));
          return result;
        } catch (error) {
          dispatch(setLoader(false));
          return Toast.error(CommonService.getError(error));
        }
      };
    };
