import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toast from "../common/Toast";

import { BsClipboardCheck } from "react-icons/bs";

import "./CopyClipboard.scss";

const CopyClipboard = (props:any) => {
    const [copySuccess, setCopySuccess] = useState(false);
    const copiedText = props?.data;
    if (copySuccess == true) {
        setCopySuccess(false)
        Toast.success("Copied");
    }
    return (
        <>
            <CopyToClipboard text={copiedText} onCopy={() => setCopySuccess(true)}>
                <span className="clip-copy pl-2">
                    <BsClipboardCheck />
                </span>
            </CopyToClipboard>
            {/* {copySuccess == true && (
				<span className="clip-success">
					Copied <BsFillPatchCheckFill />
				</span>
			)} */}
        </>
    );
};

export default CopyClipboard;