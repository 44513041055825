import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Fb from '../../../assets/Images/facebook.png';
import Insta from '../../../assets/Images/insta.png';
import Logo from '../../../assets/Images/koopLogo.png';
import LinkedIn from '../../../assets/Images/linkedin.png';
import Medium from '../../../assets/Images/medium.png';
import Telegram from '../../../assets/Images/telegram.png';
import TikTok from '../../../assets/Images/tiktok.png';
import Twitter from '../../../assets/Images/twitter.png';
import Youtube from '../../../assets/Images/youtube.png';
import { API } from "../../../config/constants";
import { RES_MESSAGES } from "../../../constants/messages";
import { RESPONSES } from "../../../constants/response";
import { ICommonApiResponse } from "../../../interfaces/common";
import { apiCallPost } from "../../../services/axios";
import ButtonCustom from "../Button/ButtonCustom";
import Toast from "../Toast";
import './Footer.scss';
const Footer = () => {
    const [errors, setErrors] = useState<any>();
    const [email, setEmail] = useState<string>("");

    // validation
    const formValidtion = () => {
        let isValidate = true;
        let errors: any = {};
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!email) {
            errors["emailErr"] = "Email is required";
            isValidate = false;
        }
        if (email) {
            if (!pattern.test(email)) {
                errors["emailErr"] = "Please enter valid email address.";
                isValidate = false;
            }
        }
        setErrors(errors);
        return isValidate;
    };

    //subscrive handler function
    const subscribeHandler = async (e: MouseEvent) => {
       // alert("here")
        e.preventDefault();
        try {
            const validate = formValidtion();
            if (validate) {
                const response = (await apiCallPost(API.SUBSCRIBE, { email: email }, "")) as ICommonApiResponse;
                if (response.status === RESPONSES.SUCCESS) {
                    setEmail("");
                    return Toast.success(RES_MESSAGES.SUBSCRIBE_SUCCESS);
                } else {
                    setEmail("");
                    return Toast.error(response.message);
                }

            }
        } catch (err: any) {
            setEmail("");
            const errMsg = err.message || RES_MESSAGES.COMMON_ERROR;
            return Toast.error(errMsg);
        }
    }
    return (
        <>
            <footer className="footer">
                <Container>
                    <Row>
                        <Col sm={6} md={4} xl={4} className="footerInfo">
                            <Link to="#"><img src={Logo} alt="" /></Link>
                            <p>Designing and implementing the Metaverse ecosystem & environment, for the next level use case of Industry 4.0 technologies. </p>
                            <p><span>KOOP LABS LLC</span> - INCORPORATED AS DAO LLC IN USA
                                {/* © 2022 ALL RIGHTS RESERVED. */}
                            </p>
                        </Col>
                        <Col sm={6} md={3} xl={4}>
                            <div className="footerImp d-table mt-4 mt-md-0 mx-md-auto">
                                <h4 className="form-label">Important</h4>
                                <ul>
                                    <li><Link to="#" onClick={() => window.open("https://koopverse.com/privacy-policy.html", "_blank")}>Privacy Policy</Link></li>
                                    <li><Link to="#" onClick={() => window.open("https://koopverse.com/terms-and-conditions.html", "_blank")}>Terms & Conditions</Link></li>
                                    <li><Link to="#" onClick={() => window.open("https://koopverse.com/contact.html", "_blank")}>Contact Us</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={12} md={5} xl={4}>
                            <div className="footerSubs d-table ms-md-auto mt-sm-4 mt-md-0">
                                <h4 className="form-label">Subscribe Us</h4>
                                <div className="footerFrom">
                                    <div className="input__wrap">
                                        <Form.Control onChange={e => { setErrors(""); setEmail(e.target.value) }} type="email" placeholder="info@koopverse.com" required />
                                        <ButtonCustom onClick={(e: MouseEvent) => subscribeHandler(e)} title="Subscribe" />
                                    </div>
                                    {/* <p className="errorMsg text-white"> Email is required</p> */}
                                    <div className="col-12 text-start">
                                        {
                                            errors && errors['emailErr'] && (<>
                                                <p className="text-danger"> {errors['emailErr']} </p>
                                            </>)
                                        }
                                    </div>
                                </div>
                                <Link to="#" onClick={() => window.open("https://www.facebook.com/KOOP360/", "_blank")}> <img src={Fb} alt="" /> </Link>
                                <Link to="#" onClick={() => window.open("https://www.instagram.com/accounts/login/?next=/KooP.360/", "_blank")}> <img src={Insta} alt="" /> </Link>
                                <Link to="#" onClick={() => window.open("https://twitter.com/KooP360", "_blank")}> <img src={Twitter} alt="" /> </Link>
                                <Link to="#" onClick={() => window.open("https://www.youtube.com/channel/UCjnEuhCgt4wKBAblnUAMOug", "_blank")}> <img src={Youtube} alt="" /> </Link>
                                <Link to="#" onClick={() => window.open("https://koop360.medium.com/", "_blank")}> <img src={Medium} alt="" /> </Link>
                                <Link to="#" onClick={() => window.open("https://t.me/KooP360", "_blank")}> <img src={Telegram} alt="" /> </Link>
                                <Link to="#" onClick={() => window.open("https://www.tiktok.com/@koopverse", "_blank")}> <img src={TikTok} alt="" /> </Link>
                                <Link to="#" onClick={() => window.open("https://www.linkedin.com/company/koop360/", "_blank")}> <img src={LinkedIn} alt="" /> </Link>

                            </div>
                        </Col>
                    </Row>
                    <p className="footerCopyRight">Koopverse © 2022. All rights reserved.</p>
                </Container>
            </footer>
        </>
    )
}
export default Footer