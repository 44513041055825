import { MouseEvent, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import ButtonCustom from "../../../components/common/Button/ButtonCustom";
import Toast from "../../../components/common/Toast";
import { isOwner } from "../../../handlers/common.handler";
import { useAppDispatch } from "../../../hooks/hooks";
import { IReduxUserDetails } from "../../../interfaces/store";
import { actionGetReceiverAddress, getOwnerAddressAction, toUpdateOwnerAddress, toUpdateReceiverAddress } from "../../../redux/actions/admin.action";
import { setLoader } from "../../../redux/actions/loader.action";
import { IS_ADMIN, WALLET, WALLET_ADDRESS } from "../../../redux/actionTypes";
import "./Settings.scss";

const Settings = () => {
  const dispatch = useAppDispatch();
  const [ownerWalletAddress, setOwnerWalletAddress] = useState("");
  const [ownerErrorMessage, setOwnerErrorMessage] = useState("");
  const [receiverWalletAddress, setReceiverWalletAddress] = useState("");
  const [receiverErrorMessage, setReceiverErrorMessage] = useState("");
  //details from redux
  const userDetails: IReduxUserDetails = useSelector((state: any) => (state.user.walletAddress) ? state.user : false);

  useEffect(() => {
    onInit();
  }, []);

  const onInit = async () => {
    //owner address
    dispatch(setLoader(true));
    const ownerAddress = (await dispatch(
      getOwnerAddressAction(userDetails.wallet)
    )) as string;
    //receiver address
    const receiver = (await dispatch(
      actionGetReceiverAddress(userDetails.wallet)
    )) as string;
    if (ownerAddress && receiver) {
      setOwnerWalletAddress(ownerAddress);
      setReceiverWalletAddress(receiver);

    }
    dispatch(setLoader(false));
  }
  //validation
  const checkValidation = () => {
    let isValidate = true;
    if (!ownerWalletAddress) {
      setOwnerErrorMessage("Owner address is required");
      isValidate = false;
    }
    if (!receiverWalletAddress) {
      setReceiverErrorMessage("Receiver address is required");
      isValidate = false;
    }
    return isValidate;
  };


  //Owner address update handler
  const updateOwnerHandler = async (e: React.MouseEvent) => {
    e.preventDefault();
    const isValidOwner = await dispatch(isOwner(ownerWalletAddress, userDetails.wallet));
    if (typeof isValidOwner === 'undefined') {
      return;
    }
    let oldOwnerAddress = await dispatch(
      getOwnerAddressAction(userDetails.wallet)
    );
    const validate = checkValidation();
    if (validate) {
      setOwnerErrorMessage("");
      if (ownerWalletAddress === oldOwnerAddress) {
        return Toast.error("Enter a new address to transfer ownership");
      }

      let result = await dispatch(
        toUpdateOwnerAddress(userDetails.wallet, {
          addressToUpdate: ownerWalletAddress,
          walletAddress: userDetails.walletAddress,
        })
      );
      if (result && result.status === true)
        Toast.success("Owner address updated successfully");
      dispatch({ type: WALLET, payload: "" });
      dispatch({ type: WALLET_ADDRESS, payload: "" });
      dispatch({ type: IS_ADMIN, payload: false });
    }
    onInit();
  };


  //Update receiver address handler
  const updateReceiverHandler = async (e: React.MouseEvent) => {
    e.preventDefault();
    const isValidOwner = await dispatch(isOwner(receiverWalletAddress, userDetails.wallet));
    if (typeof isValidOwner === 'undefined') {
      return;
    }
    let oldReceiverAddress = await dispatch(
      actionGetReceiverAddress(userDetails.wallet)
    );
    const validate = checkValidation();
    if (validate) {
      setReceiverErrorMessage("");
      if (receiverWalletAddress === oldReceiverAddress) {
        return Toast.error("Enter a different receiver address");
      }

      let result = await dispatch(
        toUpdateReceiverAddress(userDetails.wallet, {
          addressToUpdate: receiverWalletAddress,
          walletAddress: userDetails.walletAddress,
        })
      );
      if (result && result.status === true)
        Toast.success("Receiver address updated successfully");
    }
    onInit();
  };

  return (
    <div className="commn-bg setting-page">
      <Row>
        <Col lg={6} className="mb-4">
          <Form.Label>Receiver Address</Form.Label>
          <div className="input__wrap">
            <Form.Control value={receiverWalletAddress} type="text" placeholder="Receiver Address" onChange={e => setReceiverWalletAddress(e.target.value)} />
            <ButtonCustom title="Update" onClick={(event: MouseEvent) => updateReceiverHandler(event)} />
          </div>
          {receiverErrorMessage && (<>
            <p className="text-danger"> {receiverErrorMessage} </p>
          </>)
          }

        </Col>
        <Col lg={6}>
          <Form.Label>Ownership Address</Form.Label>
          <div className="input__wrap">
            <Form.Control value={ownerWalletAddress} type="text" placeholder="Ownership Address" onChange={e => setOwnerWalletAddress(e.target.value)} />
            <ButtonCustom title="Update" onClick={(event: MouseEvent) => updateOwnerHandler(event)} />
          </div>

          {ownerErrorMessage && (<>
            <p className="text-danger"> {ownerErrorMessage} </p>
          </>)
          }

        </Col>
      </Row>
    </div>
  );
};

export default Settings;
