import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import AboutSec from "./Components/AboutSec/AboutSec";
import FAQs from "./Components/FAQs/FAQs";
import PlatformSec from "./Components/PlatformSec/PlatformSec";
import "./Home.scss";

const Home = () => {
  return (
    <>
      <Header />
      <PlatformSec />
      <div className="commonbg">
        <AboutSec />
        <FAQs />
        <Footer />
      </div>
    </>
  );
};
export default Home;
