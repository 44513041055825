import { MouseEvent } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/Images/logo.png";
import { useAppDispatch } from "../../../hooks/hooks";
import * as types from "../../../redux/actionTypes";
import "./SidebarStyle.scss";




// const AdminSidebar = () => {

const AdminSidebar = ({ sidebarHandler }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
   // location based Page active class
   const location = useLocation();
   const { pathname } = location;
   const splitLocation = pathname.split("/admin");
   // location based Page active class
   const list = [
    {
      id:1,
      title: "Dashboard",
      to: "dashboard",
      location: "/dashboard",
      locationOr: "",
      locationOrAnd: "/"
     }, 
    {
      id:2,
      title: "Ticket Info",
      to: "ticket-info",
      location: "/ticket-info"
    },
    {
      id:3,
      title: "Time Mangement",
      to: "time-management",
      location: "/time-management"
    },
    {
      id:4,
      title: "Price Mangement",
      to: "price-management",
      location: "/price-management",
    },
    {
      id:5,
      title: "Setting",
      to: "setting",
      location: "/setting",
    }
   ]
  
  const logoutHandler = (event: MouseEvent) => {
    event.preventDefault();
    dispatch({ type: types.WALLET_ADDRESS, payload: "" });
    dispatch({ type: types.WALLET, payload: "" });
    dispatch({ type: types.IS_ADMIN, payload: false });
    dispatch({ type: types.TOKEN, payload: "" });
    navigate("/admin/login");

  }

  return (
    <>
      <Sidebar className="siderbarStyle sidebar">
        <div className="siderbarStyle__logo">
          <img src={logo} alt="logo" />
        </div>

         <Menu> 
          {list.map((item, i) => (
            <MenuItem onClick={sidebarHandler} key={i} className={`${splitLocation[1] === `${item.location}` || splitLocation[1] === `${item.locationOr}` || splitLocation[1] === `${item.locationOrAnd}` ? "active" : ""}`} routerLink={<Link to={item.to} />} >{item.title}</MenuItem>
            // <MenuItem  key={i} className={`${splitLocation[1] === `${item.location}` || splitLocation[1] === `${item.locationOr}` || splitLocation[1] === `${item.locationOrAnd}` ? "active" : ""}`} routerLink={<Link to={item.to} />} >{item.title}</MenuItem>
          
))}
          <MenuItem key="logout" routerLink={<Link to="" onClick={e => logoutHandler(e)} />} >∅ Logout</MenuItem>

        </Menu>
      </Sidebar>
    </>
  );
};
export default AdminSidebar;
