import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
interface PropType {
  component: React.FC;
}

const AdminAuthGuard: FC<PropType> = ({ component: Component }) => {
  const authToken = useAppSelector((state) => state.user.token);
  const isAdminLogin = useAppSelector((state) => state.user.isAdmin);

  if (authToken !== "" && isAdminLogin) {
    return <Component />;
  } else {
    return (
      <>
        <Navigate to="/admin/login" />{" "}
      </>
    );
  }

};

export default AdminAuthGuard;
