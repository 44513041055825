export function ArrowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.731" height="10.358" viewBox="0 0 14.731 10.358">
      <path id="right-arrow" d="M9.92,10.2a.522.522,0,0,1-.742-.735L12.942,5.7H.52A.517.517,0,0,1,0,5.183a.523.523,0,0,1,.52-.527H12.942L9.178.9a.533.533,0,0,1,0-.742.52.52,0,0,1,.742,0l4.656,4.656a.511.511,0,0,1,0,.735Z" fill="#fbd254" />
    </svg>
  );
}

export function BarsIcon() {
  return (
    <svg
      width="52"
      height="36"
      viewBox="0 0 52 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.5C0 1.11929 1.11929 0 2.5 0H49.5C50.8807 0 52 1.11929 52 2.5V2.5C52 3.88071 50.8807 5 49.5 5H2.5C1.11929 5 0 3.88071 0 2.5V2.5Z"
        fill="white"
      />
      <path
        d="M0 18C0 16.6193 1.11929 15.5 2.5 15.5H49.5C50.8807 15.5 52 16.6193 52 18V18C52 19.3807 50.8807 20.5 49.5 20.5H2.5C1.11929 20.5 0 19.3807 0 18V18Z"
        fill="white"
      />
      <path
        d="M0 33.5C0 32.1193 1.11929 31 2.5 31H49.5C50.8807 31 52 32.1193 52 33.5V33.5C52 34.8807 50.8807 36 49.5 36H2.5C1.11929 36 0 34.8807 0 33.5V33.5Z"
        fill="white"
      />
    </svg>
  );
}
export function BarsOpenIcon() {
  return (
    <svg
      width="52"
      height="36"
      viewBox="0 0 52 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.5C0 1.11929 1.11929 0 2.5 0H49.5C50.8807 0 52 1.11929 52 2.5V2.5C52 3.88071 50.8807 5 49.5 5H2.5C1.11929 5 0 3.88071 0 2.5V2.5Z"
        fill="white"
      />
      <path
        d="M0 18C0 16.6193 1.11929 15.5 2.5 15.5H36.1068C38.9567 15.5 39.5866 19.5043 36.8743 20.3793V20.3793C36.6263 20.4593 36.3673 20.5 36.1068 20.5H2.5C1.11929 20.5 0 19.3807 0 18V18V18Z"
        fill="#fbd254"
      />
      <path
        d="M0 33.5C0 32.1193 1.11929 31 2.5 31H49.5C50.8807 31 52 32.1193 52 33.5V33.5C52 34.8807 50.8807 36 49.5 36H2.5C1.11929 36 0 34.8807 0 33.5V33.5Z"
        fill="white"
      />
    </svg>
  );
}