import { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import "./AdminLayout.scss";

const AdminLayout = () => {

  const [closeSidebar, setCloseSidebar] = useState<boolean>(false);

  const sidebarHandler = (e: MouseEvent) => {
    e.preventDefault();
    setCloseSidebar(!closeSidebar);
  };
  return (
    <div className="admin-layout">
      <AdminSidebar sidebarHandler={sidebarHandler} />
      <AdminHeader sideBarToggle={closeSidebar} />
      {/* <AdminSidebar /> */}
      {/* <AdminHeader  /> */}
      <main className="admin-content-handle">

        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;
