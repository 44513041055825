export const KOOPVESE_ABI: any = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "totalAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "tickets",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "phase",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "priceOfEachTicketInBNB",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "email",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "timeType",
        type: "uint8",
      },
    ],
    name: "BuyTicketEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "tickets",
        type: "uint64",
      },
    ],
    name: "Phase3TicketSetter",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "phase",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "price",
        type: "uint64",
      },
    ],
    name: "PriceSetter",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint32[3]",
        name: "startTime",
        type: "uint32[3]",
      },
      {
        indexed: false,
        internalType: "uint32[3]",
        name: "endTime",
        type: "uint32[3]",
      },
    ],
    name: "TimeSetter",
    type: "event",
  },
  {
    inputs: [],
    name: "PhaseDetails",
    outputs: [
      {
        internalType: "uint32",
        name: "startTime",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "endTime",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "ticketSold",
        type: "uint32",
      },
      {
        internalType: "uint128",
        name: "price",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PhaseNumber",
    outputs: [
      {
        internalType: "enum TicketBuyingLibrary.PhaseNumber",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "TimeType",
    outputs: [
      {
        internalType: "enum TicketBuyingLibrary.TimeType",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "_tickets",
        type: "uint32",
      },
      {
        internalType: "bytes",
        name: "_emailId",
        type: "bytes",
      },
      {
        internalType: "enum TicketBuyingLibrary.TimeType",
        name: "_type",
        type: "uint8",
      },
    ],
    name: "buyTicket",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "getCurrentPhase",
    outputs: [
      {
        internalType: "enum TicketBuyingLibrary.PhaseNumber",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum TicketBuyingLibrary.PhaseNumber",
        name: "_phaseNumber",
        type: "uint8",
      },
      {
        internalType: "uint32",
        name: "_tickets",
        type: "uint32",
      },
    ],
    name: "getTotalPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "_totalPrice",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getTotalTicketCount",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_receiverAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_ownerAddress",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum TicketBuyingLibrary.PhaseNumber",
        name: "_phaseNumber",
        type: "uint8",
      },
    ],
    name: "perTicketPriceInBNB",
    outputs: [
      {
        internalType: "uint256",
        name: "perTicketPrice",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum TicketBuyingLibrary.PhaseNumber",
        name: "",
        type: "uint8",
      },
    ],
    name: "phase",
    outputs: [
      {
        internalType: "uint32",
        name: "startTime",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "endTime",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "ticketSold",
        type: "uint32",
      },
      {
        internalType: "uint128",
        name: "price",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "receiverAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum TicketBuyingLibrary.PhaseNumber",
        name: "_phaseNumber",
        type: "uint8",
      },
      {
        internalType: "uint64",
        name: "_price",
        type: "uint64",
      },
    ],
    name: "setPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_receiverAddress",
        type: "address",
      },
    ],
    name: "setReceiverAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32[3]",
        name: "_startTime",
        type: "uint32[3]",
      },
      {
        internalType: "uint32[3]",
        name: "_endTime",
        type: "uint32[3]",
      },
    ],
    name: "setStartAndEndTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "_tickets",
        type: "uint64",
      },
    ],
    name: "setTotalPhase3Tickets",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "totalAmountRaisedInBNB",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalAmountRaisedInUSD",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalPhase3Tickets",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];