import {
  ISetPhasePrice,
  ISetPhasesTime,
  ISetPhaseThreeMaxTickets
} from "../../interfaces/common";
import { IOwnershipTransfer } from "../../interfaces/user";
import { NETWORK_DETAILS, PHASE } from "./../../config/constants";
import { ContractInstanceHandler } from "./../InstanceService";

/**
 * Method to update owner address
 * @param walletType
 * @param data
 * @returns
 */
export const updateOwnerAddressMethod = (
  walletType: string,
  data: IOwnershipTransfer
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      //calculate gas fees
      let gas = await contract.methods
        .transferOwnership(data.addressToUpdate)
        .estimateGas({ from: data.walletAddress });
      await contract.methods
        .transferOwnership(data.addressToUpdate)
        .send({ from: data.walletAddress, gas })
        .on("confirmation", () => {})
        .then((res: any) => {
          resolve(res);
        });
    } catch (error) {
      console.error("Error while updating owner address", error);
      reject(error);
    }
  });
};

/**
 * Method to update receiver address
 * @param walletType
 * @param data
 * @returns
 */
export const updateReceiverAddressMethod = (
  walletType: string,
  data: IOwnershipTransfer
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      //calculate gas fees
      let gas = await contract.methods
        .setReceiverAddress(data.addressToUpdate)
        .estimateGas({ from: data.walletAddress });
      await contract.methods
        .setReceiverAddress(data.addressToUpdate)
        .send({ from: data.walletAddress, gas })
        .on("confirmation", () => {})
        .then((res: any) => {
          resolve(res);
        });
    } catch (error) {
      console.error("Error while updating receiver address", error);
      reject(error);
    }
  });
};

/**
 * Method to get receiver address
 * @param {*} walletType
 * @returns
 */
export const getReceiverAddress = async (walletType: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      let receiverAddress = await contract.methods.receiverAddress().call();
      resolve(receiverAddress);
    } catch (error) {
      console.error("Error while getting receiver address", error);
      reject(error);
    }
  });
};

/**
 * Method to get receiver address
 * @param {*} walletType
 * @returns
 */
export const getOwnerAddressMethod = async (walletType: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      let ownerAddress = await contract.methods.owner().call();
      resolve(ownerAddress);
    } catch (error) {
      console.error("Error while getting owner address", error);
      reject(error);
    }
  });
};

/**
 * Method to set phase price
 * @param walletType
 * @param data
 * @returns
 */
export const setPriceInPhaseMethod = (
  walletType: string,
  data: ISetPhasePrice
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      //calculate gas fees
      let gas = await contract.methods
        .setPrice(data.phaseNumber, data.price)
        .estimateGas({ from: data.walletAddress });
      await contract.methods
        .setPrice(data.phaseNumber, data.price)
        .send({ from: data.walletAddress, gas })
        .on("confirmation", () => {})
        .then((res: any) => {
          resolve(res);
        });
    } catch (error) {
      console.error("Error while setting phase price", error);
      reject(error);
    }
  });
};

/**
 * Method to set phase three max tickets
 * @param walletType
 * @param data
 * @returns
 */
export const setPhaseThreeMaxTicketsMethod = (
  walletType: string,
  data: ISetPhaseThreeMaxTickets
) => {
  return new Promise(async (resolve, reject) => {
    console.log("setPhaseThreeMaxTicketsMethod", data);
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      //calculate gas fees
      let gas = await contract.methods
        .setTotalPhase3Tickets(data.tickets)
        .estimateGas({ from: data.walletAddress });
      await contract.methods
        .setTotalPhase3Tickets(data.tickets)
        .send({ from: data.walletAddress, gas })
        .on("confirmation", () => {})
        .then((res: any) => {
          resolve(res);
        });
    } catch (error) {
      console.error("Error while setting third phase max price", error);
      reject(error);
    }
  });
};

/**
 * Method to get receiver address
 * @param {*} walletType
 * @returns
 */
export const getPhasesPriceInBNBMethod = async (walletType: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      let phaseOnePrice = await contract.methods.phase(PHASE.ONE).call();
      let phaseTwoPrice = await contract.methods.phase(PHASE.TWO).call();
      let phaseThreePrice = await contract.methods.phase(PHASE.THREE).call();
      const allPhasePrice = {
        phaseOnePrice:
          phaseOnePrice?.price / 10 ** NETWORK_DETAILS.USD_DECIMALS,
        phaseTwoPrice:
          phaseTwoPrice?.price / 10 ** NETWORK_DETAILS.USD_DECIMALS,
        phaseThreePrice:
          phaseThreePrice?.price / 10 ** NETWORK_DETAILS.USD_DECIMALS,
      };

      resolve(allPhasePrice);
    } catch (error) {
      console.error("Error while getting per ticket price in  BNB", error);
      reject(error);
    }
  });
};

/**
 * Get details of single phase
 * @param walletType
 * @returns
 */
export const getSinglePhaseInfoMethod = (walletType: string, phase: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      const phaseInfo = await contract?.methods.phase(phase).call();
      const data = {
        endTime: phaseInfo.endTime,
        price: phaseInfo.price,
        startTime: phaseInfo.startTime,
        ticketSold: phaseInfo.ticketSold,
      };
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Method to get receiver address
 * @param {*} walletType
 * @returns
 */
export const getTotalPhaseThreeTicketsMethod = async (walletType: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      let result = await contract.methods.totalPhase3Tickets().call();
      resolve(result);
    } catch (error) {
      console.error(
        "Error while getting tickets number to be sold in phase three",
        error
      );
      reject(error);
    }
  });
};


/**
 * Method to get  total tickets sold & amount raised in BNB
 * @param walletType 
 * @returns 
 */
export const getDashBoardInfoMethod = async (walletType: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      const getTotalTicketCount = await contract.methods
        .getTotalTicketCount()
        .call();
      const totalAmountRaisedInBNB = await contract.methods
        .totalAmountRaisedInBNB()
        .call();
      console.log("totalAmountRaisedInBNB", totalAmountRaisedInBNB);

      const data = {
        totalTicketsSold: getTotalTicketCount,
        totalAmountRaisedInBNB: totalAmountRaisedInBNB,
      };
      resolve(data);
    } catch (error) {
      console.error(
        "Error while getting dashboard information",
        error
      );
      reject(error);
    }
  });
};



/**
 * Method to set phases time
 * @param walletType 
 * @param data 
 * @returns 
 */
export const setAllPhasesTimeMethod = (
  walletType: string,
  data: ISetPhasesTime
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = await ContractInstanceHandler.koopVerseContractInstance(
        walletType
      );
      //calculate gas fees
      let gas = await contract.methods
        .setStartAndEndTime(data.startTimePayload, data.endTimePayload)
        .estimateGas({ from: data.walletAddress });
      await contract.methods
        .setStartAndEndTime(data.startTimePayload, data.endTimePayload)
        .send({ from: data.walletAddress, gas })
        .on("confirmation", () => {})
        .then((res: any) => {
          resolve(res);
        });
    } catch (error) {
      console.error("Error while setting phases times", error);
      reject(error);
    }
  });
};
