import moment from "moment";
const BigNumber = require("big-number");

const exponentialToDecimal = (exponential: number) => {
  let decimal = exponential.toString().toLowerCase();
  if (decimal.includes("e+")) {
    const exponentialSplitted = decimal.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text: string) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};

const convertWithDecimal = (value: any, decimal: any) => {
  if (value > 0) {
    if (isInt(value)) {
      value = parseInt(value);
      value = BigNumber(value).multiply(decimal);
    } else {
      value = Math.round(value * decimal);
      value = toFixed(value);
      value = parseInt(value.toString().split(".")[0]);
      value = toFixed(value);
      value = BigNumber(value);
    }
    return value.toString();
  } else {
    return 0;
  }
};

const convertBackToNormal = (value: any, decimal: number) => {
  let x = BigNumber(value).toString();
  let y = BigNumber(decimal).toString();
  let result = BigNumber(x).divide(y).toString();
  return result;
};

const toFixed = (x: any) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

const isInt = (n: number) => {
  return n % 1 === 0;
};

// const getError = (error: any) => {
//   let finalErr;
//   let errorMsg =
//     error && error.message ? error.message : "Something went wrong";
//   if (errorMsg.indexOf("Internal JSON-RPC error") > -1 || 0) {
//     let msg = errorMsg.replace("Internal JSON-RPC error.", "");
//     msg = JSON.parse(msg);

//     if (msg.message.includes("stack limit reached")) {
//       finalErr = "Enter less amount.";
//     } else {
//       finalErr = msg.message.split(":")[1];
//     }
//     return finalErr;
//   } else if (errorMsg.indexOf("execution reverted: Koopverse:") > -1) {
//     errorMsg = error.message.split("{")[0].split(":")[2];
//     return errorMsg;
//   } else if (errorMsg.indexOf("Error: Internal JSON-RPC error") == -1) {
//     let errorMsg1 = error.message.split("{");
//     return errorMsg1;
//   } else {
//     return errorMsg;
//   }
// };

const getError = (error: any) => {
  let finalErr;
  let errorMsg =
    error && error.message ? error.message : "Something went wrong";
  if (errorMsg.indexOf("Internal JSON-RPC error") > -1 || 0) {
    let msg = errorMsg.replace("Internal JSON-RPC error.", "");
    msg = JSON.parse(msg);
    if (msg.message.includes("stack limit reached")) {
      finalErr = "Enter less amount.";
    } else {
      if (errorMsg.indexOf("execution reverted: Koopverse:")) {
        if (errorMsg.indexOf("err:") > -1) {
          finalErr = "Insufficient balance";
        } else {
          finalErr = msg.message.split(":")[2];
        }
        
      } else {
        finalErr = msg.message.split(":")[1];
      }
    }
    return finalErr;
  } else if (errorMsg.indexOf("execution reverted:") > -1) {
    if (errorMsg.indexOf("execution reverted: Koopverse:")) {
      errorMsg = error.message.split("{")[0].split(":")[2];
    } else {
      errorMsg = error.message.split("{")[0].split(":")[1];
    }
    return errorMsg;
  } else if (errorMsg.indexOf("Error: Internal JSON-RPC error") == -1) {
    if (error?.error) {
      let errorMsg1 = error.error;
      return errorMsg1;
    } else if (
      errorMsg.includes(
        'invalid BigNumber value (argument="value", value=null, code=INVALID_ARGUMENT, version=bignumber/5.7.0)'
      )
    ) {
      let _err: string = "Invalid Bignumber";
      return _err;
    } else {
      let errorMsg1 = error.message?.split("{");
      return errorMsg1;
    }
  } else {
    return errorMsg;
  }
};

const custmizeAddress = (address: string) => {
  let firstFive = address.substring(0, 5);
  let lastFour = address.substr(address.length - 4);
  return firstFive + "..." + lastFour;
};

const fixedToDecimal = (value: any, decimals = 4) => {
  value =
    value && parseFloat(value) > 0
      ? decimals === 2
        ? value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        : value.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]
      : 0;
  return value;
};

const checkIfDecimalValue = (value: any) => {
  if (value.indexOf(".") === -1) {
    return true;
  } else {
    return false;
  }
};

const addMonthsToTimeStampAndReturnDaysLeft = (
  timestamp: number,
  month: number
) => {
  let data = new Date(timestamp * 1000);
  // let makeUtcTime = moment.utc(data).add(month, "months");
  // let leftDays = moment(makeUtcTime).diff(moment(), "days");
  let makeUtcTime = moment.utc(data).add(month, "months");
  let leftDays = moment(makeUtcTime).diff(moment(), "days");
  // console.log("leftDays", leftDays);
  if (Number(leftDays) >= 0) {
    return false;
  } else {
    return true;
  }
};

const formatDateToReadAble = (timestamp: number) => {
  let data = new Date(timestamp * 1000);
  var date = moment(data).format("DD/MMM/YYYY HH:mm A");
  return date;
};

const checkifSeedSaleEnded = (timestamp: number) => {
  let timeleft;
  let data = new Date(timestamp * 1000);
  let betTime = moment.utc(data);
  let leftDays: any = moment.duration(moment(betTime).diff(moment()));
  let days = parseInt(leftDays.asDays());
  let hrs = parseInt(leftDays.asHours()) % 24;
  let min = parseInt(leftDays.asMinutes()) % 60;
  let sec = parseInt(leftDays.asSeconds()) % 60;
  if (days >= 0 && hrs >= 0 && min >= 0 && sec >= 0) {
    timeleft = false;
  } else {
    timeleft = true;
  }
  return timeleft;
};

// const getGraphDataFormat = (
//   filter: number,
//   data: Array<IGraphPayload>,
//   metaTokenDecimals: number
// ) => {
//   if (filter === GRAPH_FILTER_TYPES.DAY) {
//     let result = [];
//     for (const item of data) {
//       //let time = new Date(item.buy_time * 1000);
//       const date = moment(item.buy_time).format("h:mm A");
//       result.push({
//         name: date,
//         tokens: item.token_amount / metaTokenDecimals,
//       });
//     }
//     return result;
//   } else if (filter === GRAPH_FILTER_TYPES.WEEK) {
//     let result = [];
//     for (const item of data) {
//       const date = moment(item.buy_time).utc().format("MMM DD");
//       result.push({
//         name: date,
//         tokens: item.token_amount / metaTokenDecimals,
//       });
//     }
//     result.sort((a: any, b: any) => a.valueOf() - b.valueOf());
//     return result;
//   } else if (filter === GRAPH_FILTER_TYPES.MONTH) {
//     let result = [];
//     for (const item of data) {
//       const date = moment(item.buy_time).format("MMM DD");
//       result.push({
//         name: date,
//         tokens: item.token_amount / metaTokenDecimals,
//       });
//     }
//     result.sort((a: any, b: any) => a.valueOf() - b.valueOf());
//     return result;
//   } else {
//     //filter is of year
//     let result = [];
//     for (const item of data) {
//       const date = moment(item.buy_time).format("MMM YYYY");
//       result.push({
//         name: date,
//         tokens: item.token_amount / metaTokenDecimals,
//       });
//     }

//     return result;
//   }
// };

const allowOnlyNumber = (value: any) => {
  const regex: any = /^\d*\.?\d{0,8}$/gm;

  var re = new RegExp(regex);
  if (re.test(value)) {
    return true;
  } else {
    return false;
  }
};
export const CommonService = {
  convertWithDecimal,
  toFixed,
  getError,
  custmizeAddress,
  fixedToDecimal,
  convertBackToNormal,
  checkIfDecimalValue,
  addMonthsToTimeStampAndReturnDaysLeft,
  formatDateToReadAble,
  checkifSeedSaleEnded,
  allowOnlyNumber,
  exponentialToDecimal,
};
