import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import coin from "../../../assets/SvgIcons/Coin.svg";
import graph from "../../../assets/SvgIcons/Graph.svg";
import { NETWORK_DETAILS } from "../../../config/constants";
import { useAppDispatch } from "../../../hooks/hooks";
import { IDashboardResponse } from "../../../interfaces/common";
import { IReduxUserDetails } from "../../../interfaces/store";
import { dashboardInfoAction } from "../../../redux/actions/admin.action";
import DashboardCard from "../../components/Card/DashboardCard/DashboardCard";
import "./Dashboard.scss";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const userDetails: IReduxUserDetails = useSelector((state: any) => (state.user.walletAddress) ? state.user : false);
  const [dashboardData, setDashboardData] = useState<IDashboardResponse>();
  useEffect(() => {
    onInit();
    return () => { }
  }, []);

  const onInit = async () => {
    const response = (await dispatch(dashboardInfoAction(userDetails?.wallet)));
    if (response) {
      setDashboardData(response);
    }
  }

  const data = [
    // {
    //   icon: badge,
    //   title: "Total Tickets",
    //   content: "250,000",
    // },
    // {
    //   icon: tag,
    //   title: "Available Tickets",
    //   content: "110,000",
    // },
    {
      icon: graph,
      title: "Sold Tickets",
      content: dashboardData?.totalTicketsSold ? dashboardData?.totalTicketsSold : 0,
    },
    {
      icon: coin,
      title: "Raised Fund",
      content: dashboardData?.totalAmountRaisedInBNB ? ` ${dashboardData?.totalAmountRaisedInBNB / 10 ** NETWORK_DETAILS.BNB_DECIMALS} ${NETWORK_DETAILS.CURRENCY_NAME}` : ` 0  ${NETWORK_DETAILS.CURRENCY_NAME}`,
    },
  ];

  //console.log('dashboardData', dashboardData)
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col lg={12}>
          <Row>
            {/* <Col lg={12} className="my-30 mt-0">
              <DashboardCard
                icon={trend}
                title="Today Tickets Sold"
                content="30 No"
              />
            </Col> */}

            {data.map((item, index) => (
              <Col lg={6} md={6} xs={12} key={index} className="my-30 mt-0">
                <DashboardCard
                  icon={item.icon}
                  title={item.title}
                  content={item.content}
                />
              </Col>
            ))}
          </Row>
        </Col>
        {/* <Col lg={6}>
          <div className="chart-img my-30 mt-0">
            <img src={graphImg} alt="img" />
          </div>
        </Col> */}
      </Row>
    </Container>
  );
};

export default Dashboard;
