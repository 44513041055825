import { useState, useEffect } from "react";
 import Navbar from "react-bootstrap/Navbar";
 import logo from "../../../assets/Images/logo.png";
import ConnectWallet from "../ConnectWallet/index";
import { useSelector } from "react-redux";
import { CommonService } from "../../../services/CommonService";
import ButtonCustom from "../Button/ButtonCustom";
import "./Header.scss";
import { disconnectWallet } from "../../../redux/actions/connect.action";
import { useAppDispatch } from "../../../hooks/hooks";
import { IReduxUserDetails } from "../../../interfaces/store";
import DisconnectModal from "../DisconnectModal/DisconnectModal";
import Toast from "../Toast";
const Header = () => {
  const dispatch = useAppDispatch();
  const userDetails: IReduxUserDetails = useSelector((state: any) => (state.user.walletAddress) ? state.user : false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isActive, setActive] = useState(false);
  const [view, setView] = useState(false);
  const viewClose = () => setView(false);
  const viewShow = () => setView(true);
 
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);
  useEffect(() => {
    setShow(false);
  }, [userDetails?.walletAddress]);
  //Function to disconnect wallet
  const disconnectHandler = async () => {
    dispatch(disconnectWallet(userDetails?.wallet));
    Toast.success("Disconnected successfully");
    setView(false);
  };
  return (
    <Navbar
      expand="lg"
      className={`header-style ${scroll ? " scrolled" : ""}`}
    >
         <Navbar.Brand href="#home">
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          {
            userDetails && userDetails?.walletAddress ? (
              <>
                <ButtonCustom
                className="headerBtn"
                  title={
                    `${CommonService.custmizeAddress(userDetails?.walletAddress)}`
                  }
                  onClick={(e: any) => {
                    setView(true);
                  }
                    // disconnectHandler(e)
                  }
                />
              </>
            ) : (
              <>
                <ButtonCustom
                className="headerBtn"
                  title={
                    "Connect Wallet"
                  }
                  onClick={() => {
                    handleShow();
                  }}
                />
                {/* <ButtonCustom title="Disconnect" /> */}
              </>
            )
          }
          <DisconnectModal
            show={view}
            viewShow={viewShow}
            viewClose={viewClose}
            callback={disconnectHandler}
          />
        </div>
       <ConnectWallet
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
      />
    </Navbar>
  );
};
export default Header;
