import axios, { AxiosInstance, AxiosResponse } from "axios";
import Toast from "../components/common/Toast";
import { API_HOST, SECRET_KEY } from "./../config/constants";
import { RESPONSES } from "./../constants/response";
import { setLoader } from "./../redux/actions/loader.action";
import { reduxStore } from "./../redux/store";


let store = reduxStore.getState();
// console.log("redux store", store);

// console.log('store.user.token :>> ', store.user.token);
// axios.defaults.baseURL = API_HOST;
const baseURL = API_HOST;
const axiosInstance: AxiosInstance = axios.create({ baseURL });
axiosInstance.defaults.headers.accept = "*/*";
axiosInstance.defaults.headers["Content-Type"] = "application/json";
axiosInstance.defaults.headers["Access-Control-Allow-Origin"] = "*";
axiosInstance.defaults.headers["secret-key"] = SECRET_KEY as string;

function handleSuccess(res: AxiosResponse) {
  if (res.status === RESPONSES.SUCCESS || res.status === RESPONSES.CREATED)
    res?.data?.message && Toast.success(res.data.message);
  else {
    res?.data?.message && Toast.success(res.data.message);
  }
}

function formatUrl(url: string, params: string) {
  let network = localStorage.getItem("network");
  if (!network) network = "eth";
  network = network.toUpperCase();
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}&network=${network}`
      : `?network=${network}`;
  return `${url}${params}`;
}

export const apiCallPost = (
  url: string,
  data: any,
  header :any,
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    showLoader && reduxStore.dispatch(setLoader(true));
    axiosInstance
      .post(url, data , header)
      .then((res) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        resolve(null);
      });
  });

export const apiCallGet = (
  url: string,
  params = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    //  console.log('showToast,showLoader :>> ',url,showToast, showLoader);

    showLoader && reduxStore.dispatch(setLoader(true));
    axiosInstance
      .get(url, params)
      .then((res) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        showToast && handleSuccess(res);

        resolve(res.data);
      })
      .catch((error) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        resolve(null);
      });
  });

export const apiCallPut = (
  url: string,
  data: any,
  params: any = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    showLoader && reduxStore.dispatch(setLoader(true));
    axiosInstance
      .put(formatUrl(url, params), data)
      .then((res) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        resolve(null);
      });
  });

export const apiCallPatch = (
  url: string,
  data: any,
  params: any = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    showLoader && reduxStore.dispatch(setLoader(true));
    axiosInstance
      .patch(formatUrl(url, params), data)
      .then((res) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        resolve(null);
      });
  });

export const apiCallDelete = (
  url: string,
  params: any = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    showLoader && reduxStore.dispatch(setLoader(true));
    axiosInstance
      .delete(formatUrl(url, params))
      .then((res) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        showLoader && reduxStore.dispatch(setLoader(false));
        resolve(null);
      });
  });
